import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import apiRequest from "../../utils/api.js";
import FilterDropdown from "../common/FilterDropdown.js";
import { useSelector } from "react-redux";

const WalletListFilters = (props) => {
  const [inputFields, setInputFields] = useState({
    applicationId: "",
    blockchain: [],
    crypto: [],
  });
  const [allApplications, setAllApplications] = useState([]);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    const getApplications = async () => {
      const res = await apiRequest("/get-all-application", "POST");
      if (res?.success) {
        setAllApplications(res.data);
      }
    };
    getApplications();
  }, []);

  const { setFilters, setPage, handleDrawer } = props;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name !== 'applicationId') {
      if (value.length && value[value.length - 1] === 'All') {
        value = ['All']
      } else if (value.includes('All')) {
        value = value.filter(item => item !== 'All')
      }
    }
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const onSubmit = () => {
    setPage(1);
    let blockchain = inputFields.blockchain, crypto = inputFields.crypto
    if (inputFields.blockchain.includes('All')) {
      blockchain = walletBlockchain.filter((item) => item.key !== 'All').map((item) => item.key);
    }
    if (inputFields.crypto.includes('All')) {
      crypto = walletCrypto.filter((item) => item.key !== 'All').map((item) => item.key);
    }
    const filters = {
      ...inputFields,
      blockchain,
      crypto,
    }
    setFilters(filters);
    handleDrawer(false);
  };

  const reset = () => {
    setFilters({});
    setInputFields({
      applicationId: "",
      blockchain: [],
      crypto: [],
    });
  };

  const walletBlockchain = [
    { blockchain: "All", key: "All" },
    { blockchain: "Bitcoin", key: "Bitcoin" },
    { blockchain: "Ethereum", key: "Ethereum" },
    { blockchain: "Litecoin", key: "Litecoin" },
    { blockchain: "Ripple", key: "Ripple" },
    { blockchain: "Tron", key: "Tron" },
  ];
  const walletCrypto = [
    { crypto: "All", key: "All" },
    { crypto: "Ethereum", key: "ETH" },
    { crypto: "Litecoin", key: "LTC" },
    { crypto: "Ripple", key: "XRP" },
    { crypto: "Tron", key: "TRX" },
    { crypto: "DAI", key: "DAI" },
    { crypto: "USDT", key: "USDT" },
    { crypto: "USDC", key: "USDC" },
    { crypto: "Bitcoin", key: "BTC" },
  ];

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterDropdown
          label="Application Name"
          value={inputFields.applicationId}
          name="applicationId"
          data={allApplications}
          handleInputChange={handleInputChange}
          placeholder="All"
        >
          {allApplications?.length > 0 &&
            allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?._id}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Blockchain"
          value={inputFields.blockchain || "Select an option"}
          name="blockchain"
          data={walletBlockchain}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="All"
        >
          {walletBlockchain?.length > 0 &&
            walletBlockchain.map((item, index) => {
              return (
                <MenuItem
                  className={inputFields.blockchain.includes("All") ? "Mui-selected" : ""}
                  key={`${item?.blockchain}${index}`} value={item?.key}>
                  {item?.blockchain}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Crypto"
          value={inputFields.crypto || "Select an option"}
          name="crypto"
          data={walletCrypto}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="All"
        >
          {walletCrypto?.length > 0 &&
            walletCrypto.map((item, index) => {
              return (
                <MenuItem
                  className={inputFields.crypto.includes("All") ? "Mui-selected" : ""}
                  key={`${item?.crypto}${index}`} value={item?.key}>
                  {item?.crypto}
                </MenuItem>
              );
            })}
        </FilterDropdown>
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default WalletListFilters;
