import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../../CommonTable.js";
import apiRequest from "../../../../../../utils/api.js";
import useDebounce from "../../../../../../customHook/useDebounce.js";
import { useSelector } from "react-redux";
import { getCryptoImage } from "../../../../../../utils/common.js";

const FeesTabs = (props) => {
  const { transactionType, data, getFeeSettings } = props;
  const [feeProfileType, setFeeProfileType] = useState("standard");
  const [feeData, setFeeData] = useState([]);
  const [openToast, setOpenToast] = useState("");

  const application = useSelector((state) => state?.globalSettings?.application?.data);
  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

  // Memoize the cryptoList array to prevent unnecessary rerenders
  const cryptoList = useMemo(() => [
    { crypto: "BTC" },
    { crypto: "ETH" },
    { crypto: "USDT" },
    { crypto: "USDC" },
    { crypto: "XRP" },
    { crypto: "LTC" },
    { crypto: "TRX" },
    { crypto: "DAI" },
  ], []);

  const profileTypes = [
    { label: "Standard", key: "standard" },
    { label: "VIP", key: "vip" },
    { label: "Super VIP", key: "supervip" },
  ];

  const headers = transactionType === "Exchange"
    ? [{ label: "Crypto" }, { label: "" }, ...cryptoList?.map((crypto) => ({ label: `${crypto?.crypto}` }))]
    : [{ label: "Currency" }, { label: "Fee's" }];

  const handleFeeProfileTypeChange = (item) => setFeeProfileType(item?.key);

  const handleCloseToast = (event, reason) => {
    if (reason !== "clickaway") setOpenToast("");
  };

  useEffect(() => {
    if (transactionType === "Exchange") {
      const groupedData = {};

      // Group cryptoList data by crypto
      cryptoList?.forEach((crypto) => {
        if (crypto?.crypto) {  // Only add if crypto is defined
          groupedData[crypto?.crypto] = {};
        }
      });

      // Process and group data based on transactionType and feeProfileType
      data?.[transactionType?.toLowerCase()]?.[feeProfileType]?.forEach((item) => {
        // Ensure that both fromCrypto and toCrypto are valid before adding
        if (item?.fromCrypto && item?.toCrypto) {
          if (!groupedData[item?.fromCrypto]) {
            groupedData[item?.fromCrypto] = {};
          }
          groupedData[item?.fromCrypto][item?.toCrypto] = item?.fee;
        }
      });

      // Filter out any rows with missing or undefined fromCrypto values
      const tableData = Object.keys(groupedData)
        ?.map((fromCrypto) => ({
          fromCrypto,
          ...groupedData[fromCrypto],
        }))
        ?.filter((row) => row?.fromCrypto);  // Filter out rows with undefined fromCrypto
      setFeeData(tableData);
    } else {
      // Filter non-Exchange data and ensure only valid items are included
      const tableData =
        data?.[transactionType?.toLowerCase()]?.[feeProfileType]
          ?.filter((item) => item?.crypto || item?.fromCrypto)
          ?.map((item) => ({
            fromCrypto: item?.crypto || item?.fromCrypto, // Rename `crypto` to `fromCrypto`
            ...item,
          })) || [];

      setFeeData(tableData);
    }
  }, [transactionType, feeProfileType, data, cryptoList]);

  const updateFee = async (fromCrypto, toCrypto, fee) => {
    const profileLabel = profileTypes?.find((type) => type?.key === feeProfileType)?.label || "Standard";

    const response = await apiRequest("/create-or-update-cypto-fee-setting", "POST", {
      applicationId: application?._id,
      profile: profileLabel,
      transactionType,
      fee: fee || 0,
      fromCrypto,
      toCrypto: toCrypto || "",
    });

    setOpenToast(response?.success ? "Fee setting updated successfully" : response?.error);
    if (response?.success) getFeeSettings();
  };

  const debouncedUpdateFee = useDebounce(updateFee, 1000);

  const handleFeeChange = useCallback((e, fromCrypto, toCrypto) => {
    let inputValue = e?.target?.value;
    // Remove leading zero if present
    if (inputValue?.startsWith("0") && inputValue?.length > 1 && !inputValue?.startsWith("0.")) {
      inputValue = inputValue?.substring(1);
    }
    const newFee = inputValue === "" ? "" : parseFloat(inputValue) === 0 ? inputValue : inputValue;

    // Prevent negative values or "-0"
    if (newFee === "-0" || parseFloat(newFee) < 0) {
      return;
    }

    setFeeData((prevFeeData) => {
      if (transactionType === "Exchange") {
        // Update fee for Exchange transaction types
        return prevFeeData?.map((item) => {
          if (item?.fromCrypto === fromCrypto) {
            return toCrypto
              ? { ...item, [toCrypto]: newFee }
              : { ...item, fee: newFee };
          }
          return item;
        });
      } else {
        return prevFeeData?.map((item) =>
          item._id === fromCrypto?._id ? { ...item, fee: newFee } : item
        );
      }
    });

    // Call debounced update function for fee changes
    if (transactionType === "Exchange") {
      debouncedUpdateFee(fromCrypto, toCrypto, newFee);
    } else {
      debouncedUpdateFee(fromCrypto?.fromCrypto, null, newFee);
    }
  }, [transactionType, debouncedUpdateFee]);  // Dependencies: `transactionType` and `debouncedUpdateFee`


  const renderRow = useCallback((row) => {
    return (
      <>
        <TableCell component="th" scope="row" className="w-60">
          <Box className="flex items-center gap-4">
            {getCryptoImage(row?.crypto || row?.fromCrypto, imageBaseURL)}
            <p className="text-gray-900">{row?.crypto || row?.fromCrypto || ""}</p>
          </Box>
        </TableCell>
        {transactionType === "Exchange" ? (
          <>
            <TableCell component="th" scope="row" className="w-60" />
            {cryptoList.map((crypto) => {
              const fee = row?.[crypto?.crypto] || "0";
              return (
                <>
                  <TableCell key={crypto?.crypto} className="pl-6">
                    <Box className="flex">
                      <input
                        type="number"
                        className={`border-l-2 border-y-2 rounded-l-lg pl-4 py-2 w-24 ${row?.fromCrypto === crypto?.crypto
                          ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                          : ""}`}
                        value={fee}
                        onChange={(e) => handleFeeChange(e, row?.fromCrypto, crypto?.crypto)}
                        onKeyDown={(e) => {
                          if (e?.key === "+" || e?.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        disabled={row?.fromCrypto === crypto?.crypto}
                        min="0"
                        max="100"
                      />
                      <Box
                        className={`border-r-2 border-y-2 self-center py-2 pr-8 rounded-r-lg ${row?.fromCrypto === crypto?.crypto
                          ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                          : ""}`}
                      >
                        %
                      </Box>
                    </Box>
                  </TableCell>
                </>
              );
            })}
          </>
        ) : (
          <TableCell className="pl-6">
            <Box className="flex">
              <input
                type="number"
                className="border-l-2 border-y-2 rounded-l-lg pl-4 py-2 w-24"
                value={row?.fee || "0"}
                onChange={(e) => handleFeeChange(e, row)}
                onKeyDown={(e) => {
                  if (e.key === "+" || e.key === "-") {
                    e.preventDefault();
                  }
                }}
                min="0"
                max="100"
              />
              <Box className="border-r-2 border-y-2 self-center py-2 pr-8 rounded-r-lg">
                %
              </Box>
            </Box>
          </TableCell>
        )}
      </>
    );
  }, [transactionType, imageBaseURL, handleFeeChange, cryptoList]);
  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-[8px] py-2">
          {profileTypes?.map((item, index) => (
            <Box
              key={index}
              className={`cursor-pointer px-4 py-2 rounded-md
                ${feeProfileType === item?.key ? "bg-gray-200 text-gray-600" : "text-gray-400"}`}
              onClick={() => handleFeeProfileTypeChange(item)}
            >
              {item?.label}
            </Box>
          ))}
        </Box>
      </Box>

      <CommonTable headers={headers} data={feeData} renderRow={renderRow} />
      <Snackbar
        open={openToast !== ""}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        message={openToast}
      />
    </Box>
  );
};

export default FeesTabs;
