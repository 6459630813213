import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CustomTabPanel from "../common/CustomTabPanel.js";
import ApplicationList from "./Applications/ApplicationList.js";
import CryptoSettings from "./CryptoSettings/CryptoSettings.js";
import "./css/tabs.css";

const SettingsTabs = ({ currentApplication }) => {
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className='h-[90vh]'>
      <Tabs
        className="border-b-2 mx-4 customTab"
        value={tabValue}
        id="tabs"
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "black" } }}
      >
        <Tab label="Applications" {...a11yProps(0)} />
        <Tab label="Crypto Settings" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <ApplicationList />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <CryptoSettings />
      </CustomTabPanel>
    </Box>
  );
};

export default SettingsTabs;
