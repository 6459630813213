import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";

const CopyButton = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  return (
    <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
      <IconButton onClick={handleCopy}>
        {copied ? (
          <CheckIcon fontSize="small" />
        ) : (
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`}
            className="h-5"
            alt="copy"
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
