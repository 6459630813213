import React, { useState, useEffect, useCallback } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CustomTabPanel from "../../../../../common/CustomTabPanel.js";
import FeesTabs from "./FeesTabs.js";
import apiRequest from "../../../../../../utils/api.js";
import { useSelector } from "react-redux";
import "../../../../css/tabs.css";

const FeesTable = () => {
  const [tabValue, setTabValue] = useState(0);
  const [transactionType, setTransactionType] = useState("Buy");
  const [feeSettings, setFeeSettings] = useState({});

  const application = useSelector(
    (state) => state.globalSettings.application?.data
  );

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTransactionType(tabs[newValue]);
  };
  const tabs = ["Buy", "Sell", "Receive", "Send", "Exchange"];

  const getFeeSettings = useCallback(async () => {
    if (application?._id) {
      const res = await apiRequest("/crypto-fee-setting", "POST", {
        applicationId: application?._id,
      });
      if (res?.success) {
        setFeeSettings(res.data);
      }
    }
  }, [application]);

  useEffect(() => {
    getFeeSettings();
  }, [getFeeSettings]);

  return (
    <Box className="mt-8">
      <p className="font-semibold text-xl px-6">Fees</p>
      <Tabs
        className="border-b-[2px] mt-4 pl-6 customTab"
        id="tabs"
        value={tabValue}
        onChange={handleTabChange}
      >
        {tabs.map((item, index) => {
          return (
            <Tab
              label={item}
              key={`fee-table${index}`}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      {tabs.map((item, index) => {
        return (
          <CustomTabPanel
            key={`fee-tabs${index}`}
            value={tabValue}
            index={index}
          >
            <FeesTabs
              transactionType={transactionType}
              data={feeSettings}
              getFeeSettings={getFeeSettings}
            />
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};

export default FeesTable;
