import React, { useState, useEffect } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../CommonTable.js";
import { useSelector } from "react-redux";
import CopyButton from "../../../../common/CopyButton.js";

const APIKey = () => {
    const [tableData, setTableData] = useState([
        { label: "Production Key", value: "" },
        { label: "Dev Key", value: "" },
    ]);
    const [toastMessage, setToastMessage] = useState("");

    const application = useSelector((state) => state?.globalSettings?.application?.data);
    const headers = [{ label: "Key Name" }, { label: "" }];

    useEffect(() => {
        const prodKey = application?.prodApiKey?.key || "";
        const devKey = application?.testApiKey?.key || "";

        // Update the tableData to match the initial structure
        setTableData([
            { label: "Production Key", value: prodKey },
            { label: "Dev Key", value: devKey },
        ]);
    }, [application]);

    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") return;
        setToastMessage("");
    };

    const renderRow = (row) => (
        <>
            <TableCell component="th" scope="row" className="w-60">
                <Box className="flex items-center gap-4">
                    <p className="text-gray-900 w-80">{row?.label}:</p>
                </Box>
            </TableCell>
            <TableCell>
                <Box className="flex gap-2">
                    <input
                        className="w-2/5 border-[1px] p-[10px] pl-[14px] rounded-lg"
                        value={row?.value}
                        readOnly
                    />
                    <Box className="border-[1px] rounded-lg">
                        <CopyButton textToCopy={row?.value} />
                    </Box> 
                </Box>
            </TableCell>
        </>
    );

    return (
        <Box>
            <p className="font-semibold text-lg px-6 mb-6 mt-8">API Key</p>
            <CommonTable headers={headers} renderRow={renderRow} data={tableData} />
            <Snackbar
                open={Boolean(toastMessage)}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            />
        </Box>
    );
};

export default APIKey;
