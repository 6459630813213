import React, { memo } from 'react';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

const CommonDropDown = ({ placeHolder, options, value, onChange, name, image, required, onBlur, touched, errors, isMultiple, disabled,menuItemCss }) => {
    return (
        <FormControl variant="outlined" required={Boolean(required)} >
            <Select
                name={name}
                value={value}
                onChange={onChange}
                className="bg-white filter-select"
                displayEmpty
                size='small'
                onBlur={onBlur && onBlur}
                disabled={Boolean(disabled)}
                multiple={Boolean(isMultiple)}
            >
                {options?.map((option, index) => (
                    <MenuItem className={menuItemCss} key={index} value={option?.value}>
                        {option?.label}
                    </MenuItem>
                ))}
            </Select>
            {typeof value !== "boolean" ? !value.length && (
                <div className='absolute pointer-events-none text-black	top-2 left-3.5'>
                    {placeHolder}
                </div>
            ) : null}
            <FormHelperText className="!text-red-600">
                {touched && errors}
            </FormHelperText>
        </FormControl>
    );
};

export default memo(CommonDropDown);
