import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getLabelForFilter } from "../../utils/common.js";

const FiltersIndicator = (prop) => {
  const { filters, setFilters, setFilterInputFields, resetFilters } = prop;
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const [filtersArr, setFiltersArr] = useState([]);

  useEffect(() => {
    let filtersArr2 = [];
    for (const [key, value] of Object.entries(filters)) {
      const obj = { type: key, value: value };
      if (value && value.length > 0) {
        filtersArr2.push(obj);
      }
    }
    setFiltersArr(filtersArr2);
  }, [filters]);

  const formatValue = (value) => {
    if (typeof value === "string") {
      return value;
    } else if (Array.isArray(value)) {
      return value.join(", ");
    } else {
      return "";
    }
  };

  const handleRemove = (filter) => {
    if (typeof filter?.value === "string") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter?.type]: "",
      }));
      setFilterInputFields((prevFilters) => ({
        ...prevFilters,
        [filter?.type]: "",
      }));
    } else if (Array.isArray(filter?.value)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter?.type]: [],
      }));
      setFilterInputFields((prevFilters) => ({
        ...prevFilters,
        [filter?.type]: [],
      }));
    }
  };

  return (
    <Box className="flex ml-6 gap-2">
      {filtersArr &&
        filtersArr?.map((filter, index) => {
          return (
            <Box
              key={`filter${index}`}
              className="flex items-center gap-2 mb-6 mt-4"
            >
              <Box className="flex items-center gap-2 text-sm text-[#51525C] font-medium bg-[#F4F4F5] w-fit px-2 py-1 ">
                <span>{getLabelForFilter(filter?.type)}:</span>
                <span>{formatValue(filter?.value)}</span>
                <img
                  onClick={() => handleRemove(filter, index)}
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                  alt="close-icon"
                  className="w-4 h-4 cursor-pointer"
                />
              </Box>
            </Box>
          );
        })}
      {filtersArr?.length > 0 && (
        <span
          className="text-blue-600 cursor-pointer ml-4 mt-4"
          onClick={resetFilters}
        >
          Reset all ({filtersArr?.length})
        </span>
      )}
    </Box>
  );
};

export default FiltersIndicator;
