import { SET_LOADING, SET_ALL_APPLICATIONS, SET_TRANSACTION_NOTES } from "./actionTypes.js";

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: { isLoading },
});

export const setAllApplications = (allApplications) => ({
  type: SET_ALL_APPLICATIONS,
  payload: { allApplications },
});

export const setTransactionNotes = (notes) => ({
  type: SET_TRANSACTION_NOTES,
  payload: { notes },
});