import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm.js";
import LoginHeader from "./LoginHeader.js";
import { useLocation, useNavigate } from "react-router-dom";
import apiRequest from "../../utils/api.js";
import { encryptData, decryptData } from "../../utils/encryptCreds.js";
import { useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirectValue = query.get("redirect");

  const [textInput, setTextInput] = useState({
    email: "",
    password: "",
    isChecked: false,
  });

  const [isPasswordVisible, setIsPasswordVissible] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isDeleted = localStorage.getItem("isDeleted");
    if (token && isDeleted === "true") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getUserLoggedInData = await getCredentialsWithExpiry();
        if (getUserLoggedInData) {
          const currentDate = new Date();
          if (currentDate > new Date(getUserLoggedInData?.expiryDate)) {
            localStorage.removeItem("userCredentials");
          } else {
            setTextInput({
              email: getUserLoggedInData?.email,
              password: getUserLoggedInData?.password,
            });
          }
        }
      } catch (error) {
        console.error("Error in getUserLoggedInData:", error);
      }
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setTextInput({ ...textInput, [e.target.name]: e.target.checked });
    } else {
      setTextInput({ ...textInput, [e.target.name]: e.target.value });
    }
  };

  const setCredentialsWithExpiry = async (email, password, days) => {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
    const loginData = { email, password, expiryDate };
    const encryptedData = await encryptData(
      loginData,
      "12345678901234567890123456789012"
    );
    localStorage.setItem("userCredentials", JSON.stringify(encryptedData));
  };

  const getCredentialsWithExpiry = async () => {
    const getUserCredentials = localStorage.getItem("userCredentials");
    if (getUserCredentials) {
      const decryptedData = await decryptData(
        JSON.parse(getUserCredentials),
        "12345678901234567890123456789012"
      );
      return decryptedData;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (textInput?.isChecked) {
      await setCredentialsWithExpiry(textInput?.email, textInput?.password, 30);
    }
    const res = await apiRequest("/login", "POST", textInput);
    if (res?.success) {
      const isDeleted = res?.data?.isDeleted === 'true' || res?.data?.isDeleted === true;
      if (isDeleted && res?.data?.role === "ApplicationUser") {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("applicationId");
        localStorage.removeItem("isDeleted");
        setError("Application deleted.Login not permitted");
        navigate("/login");
        return;
      } else {
        setError("");
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("role", res?.data?.role);
        localStorage.setItem("applicationId", res?.data?._id);
        localStorage.setItem("isDeleted", res?.data?.isDeleted);
        if (redirectValue) {
          navigate(redirectValue);
        } else {
          navigate("/");
        }
      }
    } else {
      setError(res?.error);
    }
  };

  return (
    <div
      className="relative h-screen flex justify-center items-center bg-cover bg-center"
      style={{ backgroundImage: `url(${imageBaseURL}/images/caas-admin-ui/login.jpg)` }}
    >
      <div className="flex flex-col items-center justify-center gap-6 border-solid border-2 h-max p-2 px-8 bg-slate-50 rounded-lg bg-opacity-85 z-10 relative">
        <LoginHeader />
        <LoginForm
          textInput={textInput}
          handleChange={handleChange}
          handleFormSubmit={handleFormSubmit}
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVissible={setIsPasswordVissible}
          error={error}
        />
      </div>
    </div>
  );
};
export default Login;
