import React, { memo } from "react";
import { Box, Select, FormControl,} from "@mui/material";

const FilterDropdown = (props) => {
  const { label, value, name, children, handleInputChange, isMultiple, placeholder } = props;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        overflowY: 'auto'
      },
    },
  };
  return (
    <Box className="!rounded-xl w-60">
      <p className="text-sm mb-2 font-normal text-[#51525C]">{label}</p>
      <FormControl fullWidth size="small">
        <Select
          id="demo-simple-select"
          name={name}
          multiple={isMultiple}
          value={value}
          onChange={handleInputChange}
          MenuProps={MenuProps}
        >
          {children}
        </Select>
        {!value.length && (
          <div className='absolute pointer-events-none text-black	top-2 left-3.5'>
            {placeholder}
          </div>
        )}
      </FormControl>
    </Box>
  );
};

export default memo(FilterDropdown);
