import React, { memo, useEffect, useState } from "react";
import { Box } from "@mui/material";
import apiRequest from "../../../../utils/api.js";
import ApplicationTabs from "./ApplicationTabs.js";
import { useDispatch } from "react-redux";
import { setSingleApplication } from "../../../../redux/actions/globalSettingsAction.js";

const ApplicationUserDetails = () => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const userApplicationId = localStorage.getItem("applicationId");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const getApplication = async () => {
            const res = await apiRequest("/get-single-application", "POST", {
                applicationId: userApplicationId,
            });
            if (res?.success) {
                try {
                    dispatch(setSingleApplication(res?.data));
                } catch (err) {
                    console.error("Error in getApplication:", err);
                }
            }
        };
        getApplication();
    }, [userApplicationId, dispatch]);

    return (
        <Box className="">
            <Box className="h-[90vh] border-2 rounded-lg mt-4 flex w-full">
                <ApplicationTabs
                    tabValue={tabValue}
                    handleChange={handleChange}
                    id={userApplicationId}
                />
            </Box>
        </Box>
    );
};

export default memo(ApplicationUserDetails);
