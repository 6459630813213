import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./css/table.css"

const CommonTable = (props) => {
  const { headers, data, renderRow, handleRowClick } = props;
  return (
    <TableContainer className="border-t-[1px] border-b-[1px]">
      <Table aria-label="simple table">
        <TableHead className="bg-gray-100">
          <TableRow className="flex">
            {headers?.length > 0 &&
              headers.map((item, index) => {
                return (
                  <TableCell
                    id="table-cell"
                    className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                      item.align === "right" && "w-11"
                    }`}
                    key={`common_tbl_head_cell_${index}`}
                  >
                    {item?.label}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              onClick={() => {
                handleRowClick && handleRowClick(row, index);
              }}
              className="cursor-pointer"
              key={`common_tbl_row_${index}`}
            >
              {renderRow(row)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonTable;
