import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TransactionCountBox from "./TransactionCountBox.js";
import { useSelector } from "react-redux";

const TransactionCount = (props) => {
  const { transactionStatusCount, dateFilter } = props;
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const navigate = useNavigate();

  const handleCountClick = (status) => {
    navigate(`/transactions?status=${status}&dateFilter=${dateFilter}`);
  };

  return (
    <Box className="md:w-2/6 w-full flex flex-col gap-6">
      <TransactionCountBox
        onClick={() => handleCountClick("")}
        label="Total Transactions"
        count={transactionStatusCount?.totalTransactionCount}
        icon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/dashboard_total_transaction.png`}
            className="w-16 h-16"
            alt="count-icon"
          />
        }
      />
      <TransactionCountBox
        onClick={() => handleCountClick("Completed")}
        label={<p className="text-green-600">Completed</p>}
        count={transactionStatusCount?.totalCompleted}
        icon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/dashboard_completed.png`}
            className="w-16 h-16"
            alt="count-icon"
          />
        }
      />
      <TransactionCountBox
        onClick={() => handleCountClick("Processing")}
        label={<p className="text-blue-600">Processing</p>}
        count={transactionStatusCount?.totalProcessing}
        icon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/dashboard_processing.svg`}
            className="p-4"
            alt="count-icon"
          />
        }
      />
      <TransactionCountBox
        onClick={() => handleCountClick("Rejected")}
        label={<p className="text-orange-600">Rejected</p>}
        count={transactionStatusCount?.totalRejected}
        icon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/dashboard_rejected.png`}
            className="w-16 h-16"
            alt="count-icon"
          />
        }
      />
      <TransactionCountBox
        onClick={() => handleCountClick("Failed")}
        label={<p className="text-red-600">Failed</p>}
        count={transactionStatusCount?.totalFailed}
        icon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/dashboard_failed.png`}
            className="w-16 h-16"
            alt="count-icon"
          />
        }
      />
    </Box>
  );
};

export default TransactionCount;
