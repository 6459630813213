import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setLoading
} from "../../redux/actions/commonAction.js";
import { axiosInstance } from "../../utils/api.js";

const AxiosInterceptor = () => {
  const reduxDispatch = useDispatch();
  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => {
        reduxDispatch(setLoading(true));
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        reduxDispatch(setLoading(false));
        return response;
      },
      (error) => {
        reduxDispatch(setLoading(false));
        return Promise.reject(error);
      }
    );
  }, [reduxDispatch]);
};

export default AxiosInterceptor;
