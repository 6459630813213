import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";

const CommonTable = (props) => {
  const {
    headers,
    data,
    renderRow,
    handleRowClick,
    approxUSDBalance,
    sorting,
    setSortConfig,
    sortConfig,
    collapseComponent,
    collapseId,
    removeHeader,
  } = props;

  const handleSorting = (headerItem) => {
    
    let newOrder;
    if (Object?.entries(sortConfig || {})?.length === 0) {
      newOrder = "asc";
      setSortConfig({ orderBy: newOrder, sortBy: headerItem });
    }

    if (headerItem === sortConfig?.sortBy) {
      newOrder = sortConfig?.orderBy === "asc" ? "desc" : "asc";
      setSortConfig({ orderBy: newOrder, sortBy: headerItem });
    } else {
      newOrder = "asc";
      setSortConfig({ orderBy: newOrder, sortBy: headerItem });
    }

    sorting(newOrder, headerItem);
  };

  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  return (
    <TableContainer className="border-t-[1px] border-b-[1px]">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {removeHeader?<></>:<TableHead className="bg-gray-100">
          <TableRow>
            {headers?.map((item, index) => {
              return (
                <TableCell
                  className="!font-semibold !text-[#51525C] whitespace-nowrap overflow-hidden text-ellipsis hover:bg-gray-200 cursor-pointer"
                  key={index}
                  onClick={() =>
                    item?.sortValue &&
                    handleSorting(item?.sortValue, item?.headerItem)
                  }
                >
                  <div className="flex gap-2 items-center">
                    {item?.headerItem}
                    {item?.sortValue && (
                      <div className="flex flex-col items-center cursor-pointer shrink-0">
                        <img
                          src={`${imageBaseURL}/images/caas-admin-ui/icons/up.svg`}
                          height="4"
                          width="8"
                          alt="AscSorting"
                        />
                        <img
                          src={`${imageBaseURL}/images/caas-admin-ui/icons/down.svg`}
                          height="4"
                          width="8"
                          alt="DescSorting"
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>}

        <TableBody>
          {data?.length > 0 ? (
            data?.map((row, index) => (
              <Fragment key={index}>
              <TableRow
                onClick={() => {
                  handleRowClick && handleRowClick(row);
                }}
                className="cursor-pointer p-3"
                data-e2e ={row?.applicationRefId}
                data-e2e-transaction= {row?.transactionId}
              >
                {renderRow(row, imageBaseURL)}
              </TableRow>
              <TableRow hover={false}>
                {(collapseComponent && collapseId === row?.id) && collapseComponent}
              </TableRow>
              </Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell>No records</TableCell>
            </TableRow>
          )}
          {Number(approxUSDBalance) > 0 && (
            <TableRow>
              <TableCell align="left">Approx. USD Balance</TableCell>
              <TableCell>{`${approxUSDBalance?.toFixed(2)}`}</TableCell>
            </TableRow>
          )}
        </TableBody>

      </Table>
    </TableContainer>
  );
};

export default CommonTable;
