import { Done, Close, Warning } from "@mui/icons-material";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";

export const getStatusComponent = (status) => {
  switch (status) {
    case "CAASBuyFlow:BuyCompleted":
    case "CAASSellFlow:SellCompleted":
    case "CAASReceiveFlow:ReceiveCompleted":
    case "CAASExchangeFlow:ExchangeCompleted":
    case "CAASSendFlow:SendCompleted":
    case "WalletBalanceUpdated":
    case "WalletManagementService:TransferCompleted":
    case "CAASsweepTransferFlow:sweepTransferCompleted":
      return (
        <div className="flex gap-1 items-center justify-left">
          <div className="bg-[#ECFDF3] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#027A48]  ">
            Completed
          </div>
          <Done fontSize="small" className="text-[#027A48]" />
        </div>
      );
    case "CAASSendFlow:ErrorOccurred":
    case "CAASExchangeFlow:ErrorOccurred":
    case "CAASReceiveFlow:ErrorOccurred":
    case "CAASSellFlow:ErrorOccurred":
    case "CAASBuyFlow:ErrorOccurred":
    case "WalletManagementService:WalletTransferError":
    case "CAASBuyFlow:B2C2OrderExecutionFailed":
    case "CAASBuyFlow:B2C2ExecutionFailed":
    case "CAASSellFlow:B2C2OrderExecutionFailed":
    case "CAASSellFlow:B2C2ExecutionFailed":
    case "CAASExchangeFlow:B2C2OrderExecutionFailed":
    case "CAASExchangeFlow:B2C2ExecutionFailed":
    case "CAASSweepTransferFlow:ErrorOccurred":
      return (
        <div className="flex gap-1 items-center justify-left">
          <div className="bg-[#FEF3F2] lg:py-1 py-px lg:px-3 px-2 rounded-2xl">
            <p className="text-center lg:text-sm font-medium text-[#B42318]">
              Failed
            </p>
          </div>
          <Close fontSize="small" className="text-[#B42318]" />
        </div>
      );
    case "CAASReceiveFlow:ReceiveRejected":
    case "CAASSendFlow:SendRejected":
    case "CAASExchangeFlow:ExchangeRejected":
    case "CAASSellFlow:SellRejected":
    case "CAASBuyFlow:BuyRejected":
    case "CAASsweepTransferFlow:RejectTransaction":  
      return (
        <div className="flex gap-1 items-center justify-left">
          <div className="bg-[#ECFDF3] lg:py-1 py-px lg:px-3 px-2 rounded-2xl">
            <p className="text-center lg:text-sm font-medium text-[#B54708]">
              Rejected
            </p>
          </div>
          <Warning fontSize="small" className="text-[#B54708]" />
        </div>
      );
    case "CAASReceiveFlow:PendingManualApproval":
      return (
        <div className="flex gap-1 items-center justify-left">
          <div className="bg-[#F4F4F5] lg:py-1 py-px lg:px-3 px-2 rounded-2xl">
            <p className="text-center lg:text-sm font-medium">Pending</p>
          </div>
        </div>
      );
    default:
      return (
        <div className="flex gap-1 items-center justify-left">
          <div className="bg-[#EFF8FF] lg:py-1 py-px lg:px-3 px-2 rounded-2xl">
            <p className="text-center lg:text-sm font-medium text-[#175CD3]">
              Processing
            </p>
          </div>
        </div>
      );
  }
};

export const getCryptoImage = (cryptoSymbol, imageBaseURL, size = 10) => {
  switch (cryptoSymbol) {
    case "BTC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/bitcoin.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "ETH":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/eth.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "LTC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/itc.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "XRP":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/xrp.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "TRX":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/trx.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "DAI":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/dai.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "USDT":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/usdt.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    case "USDC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/usdc.png`}
          className={`rounded-full w-${size} h-${size}`}
          alt="icon"
        />
      );
    default:
      return null;
  }
};

export const getFilterDate = (filter) => {
  const now = moment();
  let fromDate, toDate;

  switch (filter) {
    case "1D":
      fromDate = now.clone().subtract(24, "hours");
      toDate = now.clone();
      break;

    case "2W":
      fromDate = now.clone().subtract(14, "days");
      toDate = now.clone();
      break;

    case "3M":
      fromDate = now.clone().subtract(3, "months");
      toDate = now.clone();
      break;

    case "6M":
      fromDate = now.clone().subtract(6, "months");
      toDate = now.clone();
      break;

    case "Y":
      fromDate = now.clone().subtract(1, "year");
      toDate = now.clone();
      break;

    default:
      fromDate = now.clone().subtract(1, "year");
      toDate = now.clone();
  }

  return { fromDate: fromDate.toISOString(), toDate: toDate.toISOString() };
};

export const getwebhookUrlType = (transactionType) => {
  switch (transactionType) {
    case "Buy":
      return "buyNotificationURL";
    case "Exchange":
      return "exchangeNotificationURL";
    case "Receive":
      return "receiveNotificationURL";
    case "Sell":
      return "sellNotificationURL";
    case "Send":
      return "sendNotificationURL";
    default:
      return "";
  }
};

export const transactionTypeImage = (transactionType, imageBaseURL) => {
  switch (transactionType) {
    case "Buy":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/buy.png`}
          className="w-6 h-6"
          alt="tType-icon"
        />
      );
    case "Sell":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/sell.svg`}
          className="w-6 h-6"
          alt="tType-icon"
        />
      );
    case "Send":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/send.svg`}
          className="w-6 h-6"
          alt="tType-icon"
        />
      );
    case "Exchange":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/exchange.svg`}
          className="w-6 h-6"
          alt="tType-icon"
        />
      );
    case "Receive":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/receive.svg`}
          className="w-6 h-6"
          alt="tType-icon"
        />
      );
    default:
      return null;
  }
};

export const getCryptoLink = (network, crypto, address) => {
  switch (crypto.toLowerCase()) {
    case 'btc':
    case 'ltc':
      if (network === 'testnet') {
        return `https://blockchair.com/bitcoin/testnet/address/${address}`;
      } else if (network === 'mainnet') {
        return `https://blockchair.com/address/${address}`;
      }
      break;
    case 'eth':
      if (network === 'testnet') {
        return `https://sepolia.etherscan.io/address/${address}`;
      } else if (network === 'mainnet') {
        return `https://etherscan.io/address/${address}`;
      }
      break;
    case 'trx':
      if (network === 'testnet') {
        return `https://shasta.tronscan.org/#/address/${address}`;
      } else if (network === 'mainnet') {
        return `https://tronscan.org/#/address/${address}`;
      }
      break;
    default:
      return '';
  }
};

export const getStatusIcon = (statusCode) => {
  switch (statusCode) {
    case "Locked":
      return "cross.png";
    case "In-Use":
      return "warning.png";
    default:
      return "tick.png";
  }
};

export const getLabelForFilter = (filterType) => {
  switch (filterType) {
    case "applicationId":
      return "Application ID";
    case "blockchain":
      return "Blockchain";
    case "crypto":
      return "Crypto";
    default:
      return null;
  }
};
export const onDownloadClick = (data, fileName) => {
  const ws = utils.json_to_sheet(data);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, fileName);
  writeFileXLSX(wb, `${fileName}.xlsx`);
}
