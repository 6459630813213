import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem/index.js";
import Select from "@mui/material/Select/index.js";

const DropDown = ({ options }) => {
  const [status, setStatus] = useState("Full");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Select
      value={status}
      onChange={handleChange}
      className="w-32 h-8 pr-2 select-class"
    >
      {options.map((item, index) => {
        return (
          <MenuItem value={item} sx={{ width: 120 }} key={index}>
            {item}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default DropDown;
