import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, TextField } from "@mui/material";
import apiRequest from "../../utils/api.js";
import CommonTable from "../common/CommonTable.js";
import moment from "moment";
import TableCell from "@mui/material/TableCell/index.js";
import CommonButton from "../common/CommonButton.js";
import FiltersDrawer from "../common/FiltersDrawer.js";
import WalletListFilters from "./WalletListFilters.js";
import { useSelector } from "react-redux";
import CommonPagination from "../common/CommonPagination.js";

const WalletList = () => {
  const [walletData, setWalletData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({});

  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  const fetchWalletData = useCallback(
    async () => {
      const res = await apiRequest("/get-wallets", "POST", {
        page: page,
        searchText: debouncedSearch,
        orderBy: sortConfig?.orderBy,
        sortBy: sortConfig?.sortBy,
        ...filters,
      });
      if (res?.success) {
        setWalletData(res?.data?.walletList);
        setTotalPages(res?.data?.totalCount);
      }
    },
    [page, debouncedSearch, filters, sortConfig]
  );

  const handleRowClick = (row) => {
    window.open(`/wallets/${row?.address?.key}`, "_blank");
  };

  const tableHeaders = [
    { headerItem: "Application", sortValue: "application.applicationId" },
    { headerItem: "Blockchain", sortValue: "blockchain" },
    { headerItem: "Network", sortValue: "network" },
    { headerItem: "Account ID", sortValue: "applicationUserId" },
    { headerItem: "Wallet Address", sortValue: "address.key" },
    { headerItem: "Seed Index", sortValue: "address.seedIndex" },
    { headerItem: "Last Used", sortValue: "balanceUpdated" },
    { headerItem: "Status", sortValue: "status.code" },
    { headerItem: "Approx USD Balance", sortValue: "balanceAmountUSDApprox" },
  ];
  const renderRow = (row) => {
    return (
      <>
        <TableCell component="th" scope="row">
          {row?.application?.applicationId
            ? row?.application?.applicationId
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.blockchain ? row?.blockchain : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.network ? row?.network : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.applicationUserId ? row?.applicationUserId : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.address?.key
            ? row?.address?.key.substring(0, 6) +
            "..." +
            row?.address?.key.substring(row?.address?.key.length - 6)
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.address?.seedIndex ? row?.address?.seedIndex : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.balanceUpdated
            ? moment(row?.balanceUpdated).format("DD/MM/YYYY HH:mm")
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          <span
            className={`bg-[#F4F4F5] px-4 py-2 rounded-3xl ${row?.status?.code === "Available" &&
              ` bg-[#e8f8ee] text-[#027A48] `
              }${row?.status?.code === "Locked" && ` bg-[#FEF3F2] text-[#B42318] `
              }${row?.status?.code === "In-Use" && ` bg-[#FFFAEB] text-[#B54708]`
              }`}
          >
            {row?.status?.code ? row?.status?.code : "NA"}
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.balanceAmountUSDApprox ? row?.balanceAmountUSDApprox?.toFixed(2) : "0.0"}
        </TableCell>
      </>
    );
  };

  useEffect(
    () => {
      fetchWalletData();
    }, [page, fetchWalletData]);

  const handleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  const handleSorting = (orderBy, sortBy) => {
    setSortConfig({ orderBy, sortBy });
  };

  return (
    <Box className="border-[1px] rounded-lg w-full h-full">
      <Box className="flex justify-between  p-6">
        <Typography variant="h6" className="!font-semibold" gutterBottom>
          Wallets
        </Typography>
        <Box className="flex gap-2">
          <TextField
            className="w-96"
            placeholder="Search Wallets"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <img
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                  alt="search"
                  className="w-6 h-6"
                />
              ),
            }}
          />
          <CommonButton
            text="Filters"
            bgColor="white"
            onClick={() => handleDrawer(true)}
            StartIcon={
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                alt="filter"
                className="w-6 h-6"
              />
            }
          />
        </Box>
      </Box>
      <div className="flex flex-col h-[90%]">
        <div className="overflow-x-auto">
          <CommonTable
            headers={tableHeaders}
            data={walletData}
            renderRow={renderRow}
            handleRowClick={handleRowClick}
            sorting={handleSorting}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
          />
        </div>
        <div className="mt-auto border-t-[1px]">
          <CommonPagination totalPages={totalPages} setPage={setPage} page={page} /></div></div>
      <FiltersDrawer open={isDrawerOpen} onClose={() => handleDrawer(false)}>
        <WalletListFilters
          setFilters={setFilters}
          setPage={setPage}
          handleDrawer={handleDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default WalletList;
