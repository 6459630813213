import React from "react";
import { Box } from "@mui/material";

const TransactionSummary = ({ index, transactionCount }) => {
  let data = {};

  const allLabel = [
    "All transactions:",
    "All purchases:",
    "All sales:",
    "All receipts:",
    "All sent:",
    "All exchange:",
  ];

  const transactionStatus = [
    { label: "Completed", key: "Completed" },
    { label: "Processing", key: "Processing" },
    { label: "Failed", key: "Failed" },
  ];

  switch (index) {
    case 0:
      data = transactionCount?.all;
      break;
    case 1:
      data = transactionCount?.data?.Buy;
      break;
    case 2:
      data = transactionCount?.data?.Sell;
      break;
    case 3:
      data = transactionCount?.data?.Receive;
      break;
    case 4:
      data = transactionCount?.data?.Send;
      break;
    case 5:
      data = transactionCount?.data?.Exchange;
      break;
    default:
      data = {};
  }

  return (
    <Box>
      <Box className="pl-6 pb-5 mr-6 flex justify-between">
        <p className="font-medium">{allLabel[index]}</p>
        <Box className="mr-4">
          ${" "}
          <span className="text-gray-600">
            {data?.total?.amount
              ? parseFloat(data?.total?.amount.toFixed(6))
              : 0}
          </span>{" "}
          -{" "}
          <span className="text-blue-600">
            {data?.total?.count ? data?.total?.count : 0}
          </span>
        </Box>
      </Box>
      <hr />

      <Box className="mt-3">
        {transactionStatus.map((item, index) => {
          return (
            <Box
              key={index}
              className="mx-6 py-3 border-b-[1px] flex justify-between text-sm"
            >
              <p>{item.label}</p>
              <Box className="mr-4">
                ${" "}
                <span className="text-gray-600">
                  {data?.[item?.key]?.amount
                    ? parseFloat(data?.[item?.key]?.amount.toFixed(6))
                    : 0}
                </span>{" "}
                -{" "}
                <span className="text-blue-600">
                  {data?.[item?.key]?.count ? data?.[item?.key]?.count : 0}
                </span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TransactionSummary;
