import React, { useState } from 'react'
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { styled } from "@mui/material/styles/index.js";
import { Collapse, IconButton } from '@mui/material';
import CommonTable from '../common/CommonTable.js';
import CryptoCurrencySweepLogDetails from './CryptoCurrencySweepLogDetails.js';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';

export default function CryptoListSweepLogs(props) {

  const { cryptoListObject } = props;
  delete cryptoListObject?._id;
  delete cryptoListObject?.id;
  const [open, setOpen] = useState({});
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F4F4F5",
        color: "#51525C",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  const renderRow = (row) => {
    return (
        <>
            <StyledTableCell align="left">
                {row?.symbol ? row?.symbol : "NA"}
            </StyledTableCell>
          
            <StyledTableCell align="right">
                <div align="right">
                    <IconButton
                    onClick={()=> setOpen(prev=>{
                        if(prev?.id === row?.id) return {};
                        return row;
                    })}
                    >
                      {open?.id === row?.id ? <ExpandMoreOutlined/> : <ExpandLessOutlined/>}
                    </IconButton>
                </div>
             
            </StyledTableCell>
        </>
    );
};
  return (
    <StyledTableCell style={ cryptoListObject?.length ? {} : { paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={cryptoListObject?.length} timeout="auto" unmountOnExit>
            <CommonTable
            headers={[{headerItem: 'Crypto Currency'}, { headerItem: "" }]}
            renderRow={renderRow}
            data={cryptoListObject || []}
            collapseId={open?.id}
            collapseComponent = {
              <CryptoCurrencySweepLogDetails 
              sweepTransactionObject={open?.sweepTransactionDetails}
              />
          }
            />
            </Collapse>
        </StyledTableCell>
  )
}
