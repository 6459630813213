import Datepicker from "react-tailwindcss-datepicker";
import "./styles/datePicker.css";
import moment from "moment";

const CommonDatePicker = (props) => {
  const { setDateRange, startDate = null, endDate = null } = props;

  const handleChange = (date) => {
    const { startDate, endDate } = date;
    if (startDate && endDate) {
      const startDateTime = moment(startDate).startOf('day');
      const endDateTime = moment(endDate).endOf('day');
      setDateRange(startDateTime.format("YYYY-MM-DD HH:mm:ss"), endDateTime.format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  return (
    <div id='common-datepicker-container' className="border-[1px] rounded-md border-[#a9a2a2]">
      <Datepicker value={{ startDate, endDate }} onChange={handleChange} />
    </div>
  );
};

export default CommonDatePicker;
