import React, { useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import apiRequest from "../../../utils/api.js";
import FilterDropdown from "../../common/FilterDropdown.js";
import { useSelector, useDispatch } from "react-redux";
import { setAllApplications } from "../../../redux/actions/commonAction.js";
import constant from "../../../utils/constant.js";

const FeeWalletsListFilters = (props) => {
  const {
    setFilters, 
    setPage,
    handleDrawer,
    filterInputFields,
    setFilterInputFields,
    resetFilters,
    isDrawerOpen,
  } = props;

  const dispatch = useDispatch();
  const allApplications = useSelector(
    (state) => state?.common?.allApplications
  );
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name !== 'applicationId') {
      if (value.length && value[value.length - 1] === 'All') {
        value = ['All']
      } else if (value.includes('All')) {
        value = value.filter(item => item !== 'All')
      }
    }
    setFilterInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setPage(1);
    let blockchain = filterInputFields.blockchain, crypto = filterInputFields?.crypto
    if (filterInputFields?.blockchain?.includes('All')) {
      blockchain = constant.walletBlockchain?.filter((item) => item?.key !== 'All')?.map((item) => item.key);
    }
    if (filterInputFields?.crypto?.includes('All')) {
      crypto = constant.walletCrypto?.filter((item) => item?.key !== 'All')?.map((item) => item.key);
    }
    const filters = {
      ...filterInputFields,
      blockchain,
      crypto,
    }
    setFilters(filters);
    handleDrawer(false);
  };

  const walletBlockchain = constant?.walletBlockchain;
  const walletCrypto = constant?.walletCrypto;

  useEffect(() => {
    const getApplications = async () => {
      if (isDrawerOpen && allApplications?.length === 0) {
        const res = await apiRequest("/get-all-application", "POST");
        if (res?.success) {
          dispatch(setAllApplications(res.data));
        }
      }
    };
    getApplications();
  }, [isDrawerOpen, dispatch, allApplications]);

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterDropdown
          label="Application Name"
          value={filterInputFields?.applicationId}
          name="applicationId"
          data={allApplications?.allApplications}
          handleInputChange={handleInputChange}
          placeholder="All"
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {allApplications?.allApplications?.length > 0 &&
            allApplications?.allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?._id}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Blockchain"
          value={filterInputFields.blockchain || "Select an option"}
          name="blockchain"
          data={walletBlockchain}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="All"
        >
          {walletBlockchain?.length > 0 &&
            walletBlockchain.map((item, index) => {
              return (
                <MenuItem
                  className={filterInputFields.blockchain.includes("All") ? "Mui-selected" : ""}
                  key={`${item?.blockchain}${index}`} value={item?.key}>
                  {item?.blockchain}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Crypto"
          value={filterInputFields?.crypto || "Select an option"}
          name="crypto"
          data={walletCrypto}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="All"
        >
          {walletCrypto?.length > 0 &&
            walletCrypto.map((item, index) => {
              return (
                <MenuItem
                  className={filterInputFields.crypto.includes("All") ? "Mui-selected" : ""}
                  key={`${item?.crypto}${index}`} value={item?.key}>
                  {item?.crypto}
                </MenuItem>
              );
            })}
        </FilterDropdown>
      </Box>
      <Box className="flex self-end gap-2">
        <Button
          size="large"
          variant="outlined"
          id="reset"
          onClick={resetFilters}
        >
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default FeeWalletsListFilters;
