import { SET_LOADING, SET_ALL_APPLICATIONS, SET_TRANSACTION_NOTES, } from "../actions/actionTypes.js";

const initialState = {
  loading: false,
  allApplications: [],
  allNotes: [],
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: { ...action.payload },
      };
    case SET_ALL_APPLICATIONS:
      return {
        ...state,
        allApplications: { ...action.payload },
      };
    case SET_TRANSACTION_NOTES:
      return {
        ...state,
        allNotes: { ...action.payload },
      };
    default:
      return state;
  }
};

export default commonReducer;
