import React, { useEffect, useState, useCallback } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Link,
  Breadcrumbs,
  Tabs,
  Tab,
} from "@mui/material";
import apiRequest from "../../utils/api.js";
import CustomTabPanel from "../common/CustomTabPanel.js";
import TransactionInfoTab from "./tabs/TransactionInfoTab.js";
import EventLogTab from "./tabs/EventLogTab.js";
import CommonButton from "../common/CommonButton.js";
import { useDispatch, useSelector } from "react-redux";
import SendNotificationModal from "./SendNotificationModal.js";
import ApproveForProcessingModal from "./ApproveForProcessingModal.js";
import Notes from "./tabs/Notes.js";
import { setTransactionNotes } from "../../redux/actions/commonAction.js";

const TransactionDetails = () => {
  const [transactionData, setTransactionData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [openNotificationStatus, setOpenNotificationStatus] = useState(false);
  const [openConfirmationStatus, setOpenConfirmationStatus] = useState(false);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const params = useParams();
  const dispatch = useDispatch();

  const openSendNotificationModal = () => {
    setOpenNotificationStatus(!openNotificationStatus);
  };

  const openConfirmationModal = () => {
    setOpenConfirmationStatus(!openConfirmationStatus)
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to="/"
    >
      <img
        src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_dashboard.png`}
        alt="dashboardIcon"
        className="w-6 h-6"
      />
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      component={RouterLink}
      to="/transactions"
      className="flex gap-2"
    >
      <img
        src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_transaction.png`}
        alt="dashboardIcon"
        className="w-6 h-6"
      />
      <span className="mr-4">Transactions</span>
    </Link>,
    <p key="3">
      <span className="ml-3 text-[#18181B]">{transactionData?.type}</span>
    </p>,
  ];

  const getTransaction = useCallback(async () => {
    const res = await apiRequest("/get-transactions", "POST", {
      transactionId: params.id,
    });
    if (res?.success) {
      try {
        dispatch(setTransactionNotes(res.data.transactionsList[0]));
        setTransactionData(res?.data?.transactionsList[0]);
      } catch (err) {
        console.error(err);
      }
    }
  }, [params.id, dispatch]);

  const isButtonDisabled = (transactionData) => {
    if (!transactionData) return true;

    const validTypes = ["send"];
    const pendingApprovalStatuses = [
      "caassendflow:sendcompleted",
      "caassendflow:sendrejected",
    ];

    const transactionType = transactionData?.type?.toLowerCase();
    const transactionStatusCode = transactionData?.status?.code.toLowerCase();
    const hasValidType = validTypes.includes(transactionType);
    const isPendingManualApproval = pendingApprovalStatuses.includes(
      transactionStatusCode
    );

    return !hasValidType || isPendingManualApproval;
  };

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <Box>
      <div className="flex justify-between items-center ">
        <Breadcrumbs
          separator={
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_next.png`}
              alt="dashboardIcon"
              className="w-[18px] h-[18px]"
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div className="flex items-center flex-row gap-4">
          {transactionData?.type?.toLowerCase() === "send" && (
            <CommonButton
              text="Approve for Processing"
              bgColor="black"
              onClick={openConfirmationModal}
              disabled={isButtonDisabled(transactionData)}
            />
          )}

          <CommonButton
            text="Send Notification"
            data-e2e="send-notification"
            bgColor="white"
            onClick={openSendNotificationModal}
          />
        </div>
      </div>
      {openNotificationStatus && (
        <SendNotificationModal
          open={openNotificationStatus}
          close={openSendNotificationModal}
        />
      )}
      {openConfirmationStatus && (
        <ApproveForProcessingModal
          open={openConfirmationStatus}
          close={openConfirmationModal}
        />
      )}
      <Box className="mt-6 border-[1px] rounded-xl">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-xl p-6">Transaction Details</p>
        </div>
        <Box className="">
          <Tabs
            className="border-b-[2px] mt-4 mx-6"
            value={tabValue}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",
              },
            }}
          >
            <Tab
              label="Transaction Info"
              sx={{
                "&.Mui-selected": {
                  color: "black",
                  fontSize: "16px",
                },
                "&.MuiTab-root": {
                  textTransform: "unset !important",
                  fontSize: "16px",
                  padding: "0",
                  marginRight: "14px",
                },
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="Event Log"
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
                "&.MuiTab-root": {
                  textTransform: "unset !important",
                  fontSize: "16px",
                  padding: "0",
                  marginRight: "10px",
                },
              }}
              {...a11yProps(1)}
            />
            <Tab
              label="Notes"
              className="custom-tab"
              {...a11yProps(2)}
            />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <TransactionInfoTab data={transactionData} />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <EventLogTab data={transactionData?.status?.history} />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <Notes notesList={transactionData?.notes} fetchSavedNotes={getTransaction} transactionId={transactionData?.transactionId} />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionDetails;
