import { SET_APPLICATION, SET_APPLICATION_IDS } from "../actions/actionTypes.js";

const globalSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_APPLICATION:
      return {
        ...state,
        application: {...action.payload},
      };
      case SET_APPLICATION_IDS:
        return {
          ...state,
          applicationIdsList: {...action.payload},
        }
    default:
      return state;
  }
};

export default globalSettingsReducer;
