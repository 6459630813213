import React from 'react'
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { styled } from "@mui/material/styles/index.js";
import { Collapse } from '@mui/material';

export default function CryptoCurrencySweepLogDetails(props) {
    const { sweepTransactionObject } = props;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F4F4F5",
            color: "#51525C",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
   
    const sweepTransactionObjectCheck = Object.keys((sweepTransactionObject !== null && typeof sweepTransactionObject === 'object') ? sweepTransactionObject : {})
  return (
        <StyledTableCell style={ sweepTransactionObjectCheck?.length ? {} : { paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={Boolean(sweepTransactionObjectCheck?.length)} timeout="auto" unmountOnExit>
            <div className='shadow-md px-6 py-4'>
                <pre>{JSON.stringify(sweepTransactionObject || {}, null, 2)}</pre>
            </div>
            
            </Collapse>
        </StyledTableCell>
  ) 
}
