import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import {  Box, IconButton } from "@mui/material";
import CommonTable from "../common/CommonTable.js";
import CommonPagination from "../common/CommonPagination.js";
import apiRequest from "../../utils/api.js";
import moment from "moment/moment.js";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import CryptoListSweepLogs from "./CryptoListSweepLogs.js";

const SweepLogs = () => {
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState({});
    const [sweepLogList, setSweepLogList] = useState([])
    const [sweepLogTotalCount, setSweepLogTotalCount] = useState(0)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F4F4F5",
            color: "#51525C",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    useEffect(()=>{
        (async()=>{
            try {
                if(!page) return;
                const res = await apiRequest("/get-sweep-logs", "POST", {
                    page: page,
                    orderBy:"dsc"
                  });
                  if(!res?.success) throw new Error(JSON.stringify(res?.error));
                  const result = res?.data?.getSweepLogs;
                  setSweepLogTotalCount(result?.totalCount || 0)
                  setSweepLogList(result?.sweepLogs || []);
            } catch (error) {
                console.error("Error: " + error);
            }
           
        })()
    },[page]);

    const tableHeaders = [
        { headerItem: "Created Date", sortValue: "createdDate" },
        { headerItem: "" },
    ];

    const renderRow = (row) => {
        return (
            <>
                <StyledTableCell align="left">
                    {row?.createdDate ? moment(row?.createdDate).format('DD/MM/YY HH:mm'): "NA"}
                </StyledTableCell>
              
                <StyledTableCell align="right">
                    <div align="right">
                        <IconButton
                        onClick={()=> setOpen(prev=>{
                            if(prev?.id === row?.id) return {};
                            const providers = [...(row?.providers || [])]?.map(({_id, ...item})=>{
                                const symbol = Object.keys(item || {})?.[0];
                                return ({
                                id:JSON.stringify(item),
                                symbol,
                                sweepTransactionDetails: item?.[symbol]
                              })}) 
                            return {...row, providers};
                        })}
                        >
                            {open?.id === row?.id ? <ExpandMoreOutlined/> : <ExpandLessOutlined/>}
                        </IconButton>
                    </div>
                 
                </StyledTableCell>
            </>
        );
    };
    return (
        <Box>
            <div className="flex justify-between items-center">
                <p className="font-semibold p-6">
                    Sweep Logs
                </p>
            </div>
            <div className=" flex flex-col h-[74vh]">
                <div className="overflow-x-auto">
                    <CommonTable
                        headers={tableHeaders}
                        renderRow={renderRow}
                        data={sweepLogList?.map(item=>({id: item?._id, ...item}))|| []}
                        collapseId={open?.id}
                        collapseComponent = {
                            <CryptoListSweepLogs 
                            cryptoListObject = {(open?.providers || [] )}
                            />
                        }
                    />
                </div>
                <div className="mt-auto border-t-[1px]">
                    <CommonPagination totalPages={sweepLogTotalCount} setPage={setPage} page={page} />
                </div>
            </div>
        </Box>
    )
}
export default SweepLogs;