import { Box } from "@mui/material";
import SettingsTabs from "./SettingsTabs.js";

const GlobalSettings = () => {
  return (
    <Box className="border-[1px] rounded-lg pb-4 h-full">
      <Box className="flex gap-6 p-6 items-center">
        <p className="font-semibold text-xl">Global Settings</p>
      </Box>
      <SettingsTabs />
    </Box>
  );
};

export default GlobalSettings;
