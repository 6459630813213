import { Modal } from '@mui/material';
import React from 'react';
import CommonButton from '../../../../common/CommonButton.js';
import { useSelector } from 'react-redux';
import apiRequest from '../../../../../utils/api.js';
import { useNavigate } from 'react-router-dom';

const DeleteConfirmationModal = (props) => {
    const navigate = useNavigate();
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
    const application = useSelector((state) => state?.globalSettings?.application?.data);

    const handleSoftDelete = async () => {
        const accessToken = localStorage.getItem("token");
        const res = await apiRequest("/soft-delete-application", "POST", {
            token: accessToken,
            applicationId: application?.applicationId,
        });
        if (res.success) {
            navigate("/global-settings");
        }
        else {
            console.error("Delete failed:", res.message);
        };
    }

    return (
        <Modal open={props?.open} className="flex justify-center items-center">
            <div className="flex flex-col gap-8 bg-zinc-100 p-4 px-8 h-max rounded-xl send-Notification">
                <div className='flex gap-8'>
                    <div>
                        <p className="text-xl text-zinc-900 font-semibold">
                            Are you sure, you want to delete this application?
                        </p>
                    </div>
                    <div className='flex justify-end'>
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                            alt="Close"
                            onClick={props?.close}
                            className="w-6 h-6 cursor-pointer hover:bg-gray-200"
                        />
                    </div>
                </div>
                <div className='flex gap-4 pl-8 pr-12 '>
                    <CommonButton
                        onClick={handleSoftDelete}
                        text="Confirm"
                        bgColor="black"
                        width="w-full"
                    />
                    <CommonButton
                        text="Cancel"
                        bgColor="black"
                        width="w-full"
                        onClick={props?.close}
                    />
                </div>
            </div>
        </Modal >
    );
};
export default DeleteConfirmationModal;