import React, { memo } from 'react';
import { Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';

const TransactionDetails = ({ handleZoomClick, zoomIn }) => {
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
    const items = [
        {
            label: "Transaction Type:",
            value: <div className='leading-5 text-sm font-normal'>Buy</div>
        },
        {
            label: "Transaction Status:",
            value: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>
        },
        {
            label: "AML Status:",
            value: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>
        },
        {
            label: "Beneficiary Name:",
            value: <div className='flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>Emerald Hopkins </p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Beneficiary Address:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>241 Raven-courts road, South Yorkshire,United Kingdom, WE 162</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Bank Name:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>R E Levers</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Bank Address:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>241 Raven-courts road, South Yorkshire,United Kingdom, WE 162</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>

        },
        {
            label: "Account Number:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>03605698</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Sort Code:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>20-50-21</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "IBAN:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>-</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "SWIFT:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>-</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Currency:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'>
                <img src={`${imageBaseURL}/images/caas-admin-ui/icons/circle_flags_us.png`} alt="USAFlag" className='w-6 h-6 mr-2' />
                United States dollar
                <img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' />
            </div>
        },
        {
            label: "Amount:",
            value: <div className='bg-gray text-red flex gap-2 items-center text-right leading-5 text-sm font-normal'><p>$ 493.60</p><img className="w-5 h-5" src={`${imageBaseURL}/images/caas-admin-ui/icons/copy.svg`} alt='copyIcon' /></div>
        },
        {
            label: "Reference:",
            sublabel: "Invoice payout for development services on 09.06.2023."
        },
        {
            label: "Additional Info:",
            sublabel: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
    ];
    return (
        <Box className="border-l h-full">
            <Box className="flex justify-between items-center px-4 py-4">
                <p className="font-semibold text-xl flex items-center">
                    Transaction Failed
                </p>
                {zoomIn ? <div className='flex gap-3'> <img src={`${imageBaseURL}/images/caas-admin-ui/icons/zoom_out.png`} alt='zoomOutIcon' className='cursor-pointer mr-1 h-6 w-6' onClick={handleZoomClick} />
                    <Close
                        className="cursor-pointer text-xl"
                    /></div> :
                    <img src={`${imageBaseURL}/images/caas-admin-ui/icons/zoom_in.png`} className='cursor-pointer mr-1 h-6 w-6' alt='zoomInIcon' onClick={handleZoomClick} />
                }
            </Box>
            <Divider />
            <div className='flex h-[90%]'>
                {zoomIn ? <div className='p-4 w-2/4'>
                    {items.map((item, index) => (
                        <div key={`zoomIn_container_${index}`}>
                            <Box className="flex justify-between items-center py-4">
                                <p className="text-gray-500 text-sm flex items-center w-2/5">
                                    {item?.label}
                                </p>
                                <div className='flex justify-end w-3/5'>
                                    {item?.value || item?.sublabel}
                                </div>
                            </Box>
                            {item?.label !== 'Additional Info:' ? <Divider /> : null}
                        </div>
                    ))}
                </div> : null}
                <div className={`p-4 ${zoomIn ? 'w-[50%]' : ''}`}>
                    {items.map((item, index) => (
                        <div key={index}>
                            <Box className={`justify-between items-center ${zoomIn ? 'py-4' : 'py-3.5'} ${zoomIn ? 'flex' : (item?.label === 'Reference:' || item?.label === 'Additional Info:') ? '' : 'flex'}`}>
                                <p className="text-gray-500 text-sm flex items-center w-2/5">
                                    {item?.label}
                                </p>
                                <div className={`flex ${zoomIn ? 'w-3/5 justify-end' : (item?.label === 'Reference:' || item?.label === 'Additional Info:') ? 'mt-1 leading-5 text-sm font-normal' : 'w-3/5 justify-end'}`}>
                                    {item?.value || item?.sublabel}
                                </div>
                            </Box>
                            {item?.label !== 'Additional Info:' ? <Divider /> : null}
                        </div>
                    ))}
                </div>
            </div>
        </Box>
    );
}

export default memo(TransactionDetails);
