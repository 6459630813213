import React from "react";

const CryptoLoader = () => {
  return (
    <div
      className="flex items-center justify-center h-screen w-screen fixed top-0 left-0 z-[2000] bg-black bg-opacity-5 pointer-events-none"
      style={{ backdropFilter: "blur(2px)" }}
    >
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-auto"
      />
      <div className="relative w-24 h-24 animate-flip">
        <div className="absolute w-full h-full flex items-center justify-center font-bold front">
          <img src="/logo-noText.png" alt="Logo" className="w-16 h-16" />
        </div>
        <div className="absolute w-full h-full flex items-center justify-center font-bold back">
          <img src="/logo-noText.png" alt="Logo" className="w-16 h-16" />
        </div>
      </div>
    </div>
  );
};

export default CryptoLoader;
