import React from 'react';
import Checkbox from '../common/CheckBox.js';

const SweepsSetting = () => {

    return (
        <div className="absolute top-16 right-5 z-50 bg-white p-4 rounded-lg border border-slate-100">
            <div className='flex gap-6'>
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Payment Provider</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Crypto</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Min Full Wallets<br /> Thresholds</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Min USD<br /> Thresholds</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Max USD<br /> Thresholds</p>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Take All Funds</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Archive</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Max Fee <br />Thresholds</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Max Percent<br />Thresholds</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <Checkbox />
                        <p>Sweep Flag</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SweepsSetting;