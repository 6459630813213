import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Breadcrumbs,
    Link,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { useParams, Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import EditBalance from "../EditBalance.js";
import CommonPagination from "../../common/CommonPagination.js";
import CopyButton from "../../common/CopyButton.js";
import DropDown from "../../common/DropDown.js";
import { getCryptoImage, getCryptoLink, onDownloadClick } from "../../../utils/common.js";
import CommonButton from "../../common/CommonButton.js";
import CommonTable from "../../common/CommonTable.js";
import apiRequest from "../../../utils/api.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const FeeWalletDetails = () => {
    const [searchText, setSearchText] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [openEditBalance, setOpenEditBalance] = useState(false);
    const [sortConfig, setSortConfig] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [balanceData, setBalanceData] = useState({});
    const [feeWalletData, setFeeWalletData] = useState({
        balance: [],
        balanceHistory: [],
        balanceAmountUSDApprox: [],
        feeWalletDetailObj: {},
    })
    const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);
    const params = useParams();

    const openEditBalanceModal = (balanceData) => {
        setBalanceData(balanceData);
        setOpenEditBalance(!openEditBalance);
        fetchFeeWalletDetailsData();
    };

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            component={RouterLink}
            to="/"
        >
            <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_dashboard.png`}
                alt="dashboardIcon"
                className="w-6 h-6"
            />
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            component={RouterLink}
            to="/fee-wallets"
            className="flex gap-2"
        >
            <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_wallet.png`}
                alt="dashboardIcon"
                className="w-6 h-6"
            />
            <span className="mr-4">Fee Wallets</span>
        </Link>,
        <Typography key="3" color="text.primary">
            <span className="ml-3 text-[#18181B]">
                Wallet Address: {params?.addressKey}
                <CopyButton textToCopy={params?.addressKey} />
            </span>
        </Typography>,
    ];

    const handleChangeSearchText = (e) => {
        setSearchText(e?.target?.value);
    };

    const fetchFeeWalletDetailsData = useCallback(
        async () => {
            const res = await apiRequest("/get-fee-wallet-details", "POST", {
                addressKey: params?.addressKey,
                page: page,
                searchText: debouncedSearch,
                orderBy: sortConfig?.orderBy,
                sortBy: sortConfig?.sortBy,
            });
            if (res?.success) {
                setFeeWalletData({
                    balance: res?.data?.feeWallet?.balance,
                    balanceHistory: res?.data?.feeWallet?.balanceHistory,
                    balanceAmountUSDApprox: res?.data?.feeWallet?.balanceAmountUSDApprox,
                    feeWalletDetailObj: res?.data?.feeWallet
                });
                setTotalPages(res?.data?.totalCount);
            }
        },
        [page, params?.addressKey, debouncedSearch, sortConfig]
    )
    const tableHeaders = [
        { headerItem: "Date", sortValue: "updatedDate" },
        { headerItem: "Transaction Type" },
        { headerItem: "Transaction ID" },
        { headerItem: "Asset" },
        { headerItem: "Amount", sortValue: "amount" },
        { headerItem: "Previous Balance" },
        { headerItem: "Current Balance" },
    ];

    const tableWalletInfoHeaders = [
        { headerItem: "Token" },
        { headerItem: "Balance Amount" },
        { headerItem: "Last Updated" },
        { headerItem: "" },
    ];

    const renderWalletInfoRow = (row) => {
        return (
            <>
                <StyledTableCell>
                    <Box className="flex items-center gap-2">
                        {getCryptoImage(row?.cryptoSymbol, imageBaseURL, 6)}
                        {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
                    </Box>
                </StyledTableCell>
                <StyledTableCell align="left">
                    <Box className="flex gap-4">
                        {row?.balanceAmount ? row?.balanceAmount?.toFixed(6) : "0.0"}
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
                            alt="edit-icon"
                            onClick={() => openEditBalanceModal(row)}
                            className="w-5 h-5"
                        />
                    </Box>
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.updated
                        ? moment(row?.updated).format("DD/MM/YYYY - HH:mm")
                        : "NA"}
                </StyledTableCell>
            </>
        );
    };

    const renderRow = (row) => {
        return (
            <>
                <StyledTableCell align="left">
                    {row?.updatedDate
                        ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
                        : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.transactionType ? row?.transactionType : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.transactionId ? row?.transactionId : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.amount ? row?.amount?.toFixed(6) : "0.0"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.previousBalance ? row?.previousBalance?.toFixed(6) : "0.0"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.currentBalance ? row?.currentBalance?.toFixed(6) : "0.0"}
                </StyledTableCell>
            </>
        );
    };

    const handleSorting = (orderBy, sortBy) => {
        setSortConfig({ orderBy, sortBy });
    };

    useEffect(() => {
        if (searchText.length >= 3 || searchText === "") {
            setPage(1);
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [searchText]);

    useEffect(
        (debouncedSearch) => {
            if (debouncedSearch) {
                fetchFeeWalletDetailsData();
            } else {
                fetchFeeWalletDetailsData();
            }
        },
        [page, fetchFeeWalletDetailsData, debouncedSearch]
    );

    const navigateToBlockchain = () => {
        let url = getCryptoLink(
            feeWalletData?.feeWalletDetailObj?.network,
            feeWalletData?.feeWalletDetailObj?.crypto?.symbol,
            feeWalletData?.feeWalletDetailObj?.address?.key,
        );
        window.open(url, "_blank");
    }

    return (
        <Box className="flex flex-col mb-4 gap-4">
            <Box>
                <Breadcrumbs
                    separator={
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_next.png`}
                            alt="dashboardIcon"
                            className="w-[18px] h-[18px]"
                        />
                    }
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Box className="border-[1px] rounded-lg w-full wallet-details">
                <div className="flex justify-between p-6">
                    <div>
                        <EditBalance
                            open={openEditBalance}
                            close={openEditBalanceModal}
                            currentBalance={balanceData?.balanceAmount}
                            cryptoSymbol={balanceData?.cryptoSymbol}
                            walletId={feeWalletData?.feeWalletDetailObj?._id}
                            newBalanceAmountUSDApprox={
                                feeWalletData?.balanceAmountUSDApprox}
                            fetchWalletDetailsData={fetchFeeWalletDetailsData}
                            path="/update-fee-wallet-crypto-balance"
                        />
                        <div className="font-semibold">
                            <p>
                                Ethereum Main{" "}
                            </p>
                            <DropDown options={["Full", "Available", "In Use", "Locked"]} />
                        </div>
                        {params?.addressKey} <CopyButton textToCopy={params?.addressKey} />
                    </div>
                    <div className="flex gap-2 items-center">
                        <CommonButton text="View on blockchain" bgColor="black" onClick={navigateToBlockchain} />
                    </div>
                </div>
                <Box>
                    <CommonTable
                        headers={tableWalletInfoHeaders}
                        renderRow={renderWalletInfoRow}
                        data={feeWalletData?.balance}
                        approxUSDBalance={feeWalletData?.balanceAmountUSDApprox}
                    />
                </Box>
                <Box className="flex  flex-col justify-between p-6">
                    <div>
                        <p className="font-semibold ">All Transactions</p>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex py-6">
                            <TextField
                                placeholder="Search transactions"
                                type="search"
                                size="small"
                                onChange={handleChangeSearchText}
                                value={searchText}
                                InputProps={{
                                    endAdornment: (
                                        <img
                                            src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                            alt="search"
                                            className="w-6 h-6"
                                        />
                                    ),
                                }}
                            />
                        </div>
                        <div className="items-center flex justify-center gap-2">
                            <Box onClick={() => onDownloadClick(feeWalletData?.balanceHistory, "Fee-Wallet-Table-Export")} className="cursor-pointer">
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/download_button.png`}
                                    alt="download-icon"
                                    className="w-10 h-10"
                                />
                            </Box>
                            <CommonButton
                                text="Filter"
                                bgColor="white"
                                StartIcon={
                                    <img
                                        src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                                        alt="filter"
                                        className="w-6 h-6"
                                    />
                                }
                            />
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/settings_button.png`}
                                alt="settings"
                                className="w-10 h-10"
                            />
                        </div>
                    </div>
                </Box>
                <Box>
                    <CommonTable
                        headers={tableHeaders}
                        data={feeWalletData?.balanceHistory}
                        renderRow={renderRow}
                        sorting={handleSorting}
                        setSortConfig={setSortConfig}
                        sortConfig={sortConfig}
                    />
                </Box>
                <div className="mt-2 flex justify-between p-6 w-full">
                    <CommonPagination
                        totalPages={totalPages}
                        setPage={setPage}
                        page={page}
                    />
                </div>
            </Box>
        </Box>
    );
};
export default FeeWalletDetails;

