import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Layout = ({ heading, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      const previousUrl = location.pathname
      navigate("/login?redirect=" + previousUrl);
    }
  }, [navigate, location]);

  return (
    <>
      {children}
    </>
  );
};

export default Layout;
