import React, { useState, useEffect, useCallback } from "react";
import { Box, Snackbar, TableCell, TextField } from "@mui/material";
import apiRequest from "../../../utils/api.js";
import { useSelector } from "react-redux";
import useDebounce from "../../../customHook/useDebounce.js";
import CommonTable from "../CommonTable.js";
import Checkbox from "../../common/CheckBox.js";

const CryptoSettings = () => {
  const [allCryptos, setAllCryptos] = useState([]);
  const [openToast, setOpenToast] = useState("");
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const getAllCryptos = useCallback(async () => {
    try {
      const res = await apiRequest("/list-crypto", "POST");
      if (res?.success) {
        setAllCryptos(res?.data?.CryptoSettingList);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      await getAllCryptos();
    };
    getData();
  }, [getAllCryptos]);

  const updateTradeAmount = async (row, value, field) => {
    const updateObj = {
      id: row?._id,
    };
    updateObj[field] = value;

    const res = await apiRequest("/update-crypto", "POST", updateObj);

    if (res?.success) {
      getAllCryptos();
    } else {
      setOpenToast("Crypto could not be updated.");
    }
  };

  const debouncedUpdateTradeAmount = useDebounce((row, value, field) => {
    updateTradeAmount(row, value, field);
  }, 500);

  const handleTradeAmountChange = async (e, row) => {
    const updatedTradeData = allCryptos?.map((item) =>
      item?._id === row?._id ? { ...item, [e?.target?.name]: e.target.value } : item
    );
    setAllCryptos(updatedTradeData);
    debouncedUpdateTradeAmount(row, e.target.value, e?.target?.name);
  };

  const headers = [
    { label: "Currency" },
    { label: "Min Trade Amount", align: "right" },
    { label: "Max Trade Amount", align: "right" },
    { label: "Currency Fiat Settlement Trade Config", align: "right" },
  ];

  const renderRow = (row, index) => {
    return (
      <>
        <TableCell>
          <Box display="flex" alignItems="center">
            <img
              src={`${imageBaseURL}/images${row?.icon}`}
              alt={row?.symbol}
              width={35}
              height={35}
              className="mr-3"
            />
            <Box>{row?.symbol}</Box>
          </Box>
        </TableCell>
        <TableCell align="right" className="p-2">
          <Box className="flex justify-center">
            <TextField
              name='minTradeAmt'
              size="small"
              variant="outlined"
              placeholder="0"
              value={row?.minTradeAmt}
              onChange={(e) => {
                handleTradeAmountChange(e, row)
              }}
              className="crypto-settings-text-field"
              type="number"
              inputProps={{ min: 0 }}
            />
          </Box>
        </TableCell>
        <TableCell align="right" className="p-2">
          <Box className="flex justify-center">
            <TextField
              name='maxTradeAmt'
              size="small"
              variant="outlined"
              placeholder="0"
              value={row?.maxTradeAmt}
              onChange={(e) =>
                handleTradeAmountChange(e, row)
              }
              className="crypto-settings-text-field"
              type="number"
              inputProps={{ min: 0 }}
            />
          </Box>
        </TableCell>
        <TableCell align="right" className="p-2">
          <Box className="flex justify-center">
            <Checkbox
              name="requireFiatSettlementTrade"
              checked={Boolean(row?.requireFiatSettlementTrade)}
              handleChange={(e) => {
                const event = {
                  target:{
                    name: e?.target?.name,
                    value: e?.target?.checked,
                  }
                }
                handleTradeAmountChange(event, row);
              }}
              />
          </Box>
        </TableCell>
      </>
    );
  };

  return (
    <Box className="h-[70vh] overflow-auto">
      <CommonTable headers={headers} renderRow={renderRow} data={allCryptos} />
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default CryptoSettings;
