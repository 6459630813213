import React from "react";
import { Box, TableCell } from "@mui/material";
import CommonTable from "../../common/CommonTable.js";
import moment from "moment";

const EventLogTab = ({ data }) => {
  const tableHeaders = [
    { headerItem: "Event Type" },
    { headerItem: "Event Description" },
    { headerItem: "Timestamp" },
  ];

  const renderRow = (row) => {
    return (
      <>
        <TableCell component="th" scope="row">
          {row?.code ? row.code : "NA"}
        </TableCell>
        <TableCell align="left">{row?.message ? row.message : "NA"}</TableCell>
        <TableCell align="left">
          {row?.updatedDate
            ? moment(row?.updatedDate).format("DD.MM.YY HH:mm")
            : "NA"}
        </TableCell>
      </>
    );
  };
  return (
    <Box>
      <p className="font-semibold text-lg mt-8 px-6 pb-6">Event Log</p>
      <CommonTable headers={tableHeaders} data={data} renderRow={renderRow} />
    </Box>
  );
};

export default EventLogTab;
