import { Modal } from "@mui/material";
import React from "react";
import CommonButton from "../../common/CommonButton.js";
import { useSelector } from "react-redux";

const Success = (props) => {
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const handleClick = () => {
    props.close();
    props.closeNotificationModal();
    window.location.reload();
  };

  const { status = "" } = props;
  const imageSrc =
    status === "SUCCESS"
      ? `${imageBaseURL}/images/caas-admin-ui/icons/success.png`
      : `${imageBaseURL}/images/caas-admin-ui/icons/rejected.png`;
  return (
    <Modal open={true} className="flex  justify-center items-center">
      <div className="flex flex-col items-center gap-6 bg-zinc-100 p-8 h-max rounded-xl edit-balance">
        <img src={imageSrc} height="108" width="108" alt="SUCCESS" />
        <p>{status}</p>
        <p>Notification sent successfully</p>
        <CommonButton
          text="Close"
          bgColor="black"
          width="w-full"
          borderRadius="rounded-3xl"
          onClick={handleClick}
        />
      </div>
    </Modal>
  );
};
export default Success;
