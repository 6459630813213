import React from 'react';

const Checkbox = ({ checked, handleChange, name}) => {

    return (
        <div className="flex items-center">
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={handleChange}
                className="form-checkbox h-4 w-4 text-white border-gray-300 rounded focus:ring-0 cursor-pointer"
            />
        </div>
    );
};

export default Checkbox;