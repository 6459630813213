import { Modal, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import CommonButton from '../common/CommonButton.js';

const CommonNoteModal = React.memo((props) => {
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

    return (
        <>
            <Modal open={props.open} className='flex justify-center items-center'>
                <div className='h-max px-6 py-6 bg-white w-[560px] rounded-xl send-Notification'>
                    <form className='flex flex-col gap-12' onSubmit={props.handleFormSubmit}>
                        <div className='flex justify-between'>
                            <div className='text-2xl font-semibold'>{props.title}</div>
                            <div>
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                                    alt="closeImage"
                                    className='h-6 w-6 cursor-pointer'
                                    onClick={props.close}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Note:</p>
                            <TextField
                                type='text'
                                placeholder='Add your note...'
                                variant='outlined'
                                onChange={(e) => props.handleChange(e)}
                                value={props.note}
                                name="note"
                                rows={7}
                                multiline
                                className='bg-zinc-100 w-[510px] textarea-field rounded-lg'
                            />
                        </div>
                        <div className='flex justify-end'>
                            <CommonButton
                                type='submit'
                                text="Apply"
                                bgColor="black"
                                width="w-36"
                                borderRadius='rounded-3xl' />
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
});

export default CommonNoteModal