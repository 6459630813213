import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import CommonButton from "../../common/CommonButton.js";
import CommonTable from "../../common/CommonTable.js";
import CommonPagination from "../../common/CommonPagination.js";
import TableCell from "@mui/material/TableCell/index.js";
import moment from "moment";
import { getStatusIcon } from "../../../utils/common.js";
import FiltersIndicator from "../../common/FiltersIndicator.js";
import FiltersDrawer from "../../common/FiltersDrawer.js";
import FeeWalletsListFilters from "./FeeWalletsListFilters.js";
import apiRequest from "../../../utils/api.js";

const FeeWallets = () => {
  const [feeWalletsData, setFeeWalletsData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({});
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const [filterInputFields, setFilterInputFields] = useState({
    applicationId: "",
    blockchain: [],
    crypto: [],
  });

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const fetchWalletData = useCallback(async () => {
    const res = await apiRequest("/get-fee-wallets", "POST", {
      page: page,
      searchText: debouncedSearch,
      orderBy: sortConfig.orderBy,
      sortBy: sortConfig?.sortBy,
      ...filters,
    });
    if (res?.success) {
      setFeeWalletsData(res?.data?.feeWalletList);
      setTotalPages(res?.data?.totalCount);
    }
  }, [page, debouncedSearch, filters, sortConfig]);

  const handleSorting = (orderBy, sortBy) => {
    setSortConfig({ orderBy, sortBy });
  };

  const tableHeaders = [
    { headerItem: "Application" },
    { headerItem: "Blockchain" },
    { headerItem: "Crypto" },
    { headerItem: "Network" },
    { headerItem: "Seed Index" },
    { headerItem: "Wallet Address" },
    { headerItem: "Status" },
    { headerItem: "Last Used", sortValue: "balanceUpdated" },
    { headerItem: "Balance", sortValue: "balance" },
    { headerItem: "Approx USD Balance", sortValue: "balanceAmountUSDApprox" },
  ];

  useEffect(() => {
    fetchWalletData();
  }, [page, fetchWalletData]);

  const handleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  const resetFilters = () => {
    setFilters({});
    setFilterInputFields({
      applicationId: "",
      blockchain: [],
      crypto: [],
    });
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  const handleRowClick = (row) => {
    window.open(`/fee-wallets/${row?.address?.key}`, "_blank");
  };
  return (
    <Box className="border-[1px] rounded-lg w-full h-full flex flex-col">
      <Box className="flex justify-between  p-6">
        <Typography variant="h6" className="!font-semibold" gutterBottom>
          Fee Wallets
        </Typography>
        <Box className="flex gap-2">
          <TextField
            className="w-96"
            placeholder="Search Wallets"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <img
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                  alt="search"
                  className="w-6 h-6"
                />
              ),
            }}
          />
          <CommonButton
            text="Filters"
            bgColor="white"
            onClick={() => handleDrawer(true)}
            StartIcon={
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                alt="filter"
                className="w-6 h-6"
              />
            }
          />
        </Box>
      </Box>
      <FiltersIndicator
        filters={filters}
        setFilters={setFilters}
        setFilterInputFields={setFilterInputFields}
        resetFilters={resetFilters}
      />
      <div className="flex flex-col h-[90%]">
        <div className="overflow-x-auto">
          <CommonTable
            headers={tableHeaders}
            data={feeWalletsData}
            renderRow={renderRow}
            sorting={handleSorting}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
            handleRowClick={handleRowClick}
          />
        </div>
        <div className="mt-auto border-t-[1px] ">
          <CommonPagination
            totalPages={totalPages}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
      <FiltersDrawer open={isDrawerOpen} onClose={() => handleDrawer(false)}>
        <FeeWalletsListFilters
          setFilters={setFilters}
          setPage={setPage}
          handleDrawer={handleDrawer}
          filterInputFields={filterInputFields}
          setFilterInputFields={setFilterInputFields}
          resetFilters={resetFilters}
          isDrawerOpen={isDrawerOpen}
        />
      </FiltersDrawer>
    </Box>
  );
};
const renderRow = (row, imageBaseURL) => {
  return (
    <>
      <TableCell component="th" scope="row">
        {row?.application?.applicationId
          ? row?.application?.applicationId
          : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.blockchain ? row?.blockchain : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.crypto?.symbol ? row?.crypto?.symbol : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.network ? row?.network : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.address?.seedIndex ? row?.address?.seedIndex : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        <div className="flex">
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/${getStatusIcon(
              row?.status?.code
            )}`}
            alt="reload-icon"
            className="w-5 h-5 mr-4"
          />
          <span className="text-blue-600">
            {row?.address?.key
              ? row?.address?.key.substring(0, 6) +
              "..." +
              row?.address?.key.substring(row?.address?.key.length - 6)
              : "NA"}{" "}
          </span>
        </div>
      </TableCell>
      <TableCell component="th" scope="row">
        <span
          className={`bg-[#F4F4F5] px-4 py-2 rounded-3xl ${row?.status?.code === "Available" && ` bg-[#e8f8ee] text-[#027A48] `
            }${row?.status?.code === "Locked" && ` bg-[#FEF3F2] text-[#B42318] `
            }${row?.status?.code === "In-Use" && ` bg-[#FFFAEB] text-[#B54708]`}`}
        >
          {row?.status?.code ? row?.status?.code : "NA"}
        </span>
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.balanceUpdated
          ? moment(row?.balanceUpdated).format("DD/MM/YYYY HH:mm")
          : "NA"}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.balance?.[0]?.balanceAmount
          ? row?.balance?.[0]?.balanceAmount?.toFixed(6) + " " + row?.crypto?.symbol
          : `0.0 ${row?.crypto?.symbol}`}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.balanceAmountUSDApprox ? row?.balanceAmountUSDApprox?.toFixed(2) : '0.0'}
      </TableCell>
    </>
  );
};

export default FeeWallets;