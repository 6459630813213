import React from "react";

const LoginHeader = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src="/logo.png" alt="CAAS Logo" className="h-7 mt-3" />
      <img src="/text.png" alt="CAAS Logo" className="h-9 mt-5 mb-3" />
      <p className="font-normal text-base text-gray-500">
        Welcome back! Please enter your details.
      </p>
    </div>
  );
};
export default LoginHeader;
