import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import FeesTable from "./Fees/FeesTable.js";
import ThresholdApprovalTable from "./ThresholdApproval/ThresholdApprovalTable.js";
import CryptoTab from "./Crypto/CryptoTab.js";
import CustomTabPanel from "../../../../common/CustomTabPanel.js";
import "../../../css/tabs.css"

const GlobalCryptoTab = () => {
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabs = ["Fees", "Threshold Approval","Crypto"];
  return (
    <Box>
      <Tabs
        className=" mt-4 bg-gray-100 p-1 ml-6 rounded-lg w-fit customTabs min-h-0 customTab"
        value={tabValue}
        onChange={handleTabChange}
      >
        {tabs.map((item, index) => {
          return (
            <Tab
              label={item}
              key={`cryptoTab${index}`}
              className={`${
                tabValue === index && "!bg-white !rounded-lg !p-0 !shadow-xl "
              } !min-h-fit !px-3 `}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>

      <CustomTabPanel value={tabValue} index={0}>
        <FeesTable />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <ThresholdApprovalTable />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <CryptoTab />
      </CustomTabPanel>
    </Box>
  );
};

export default GlobalCryptoTab;
