import { SET_APPLICATION, SET_APPLICATION_IDS } from "./actionTypes.js";

export const setSingleApplication = (data) => ({
  type: SET_APPLICATION,
  payload: { data },
});

export const setApplicationIds = (appllicationIds) => ({
  type: SET_APPLICATION_IDS,
  payload: { appllicationIds },
});