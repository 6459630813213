import React, { useState, useCallback, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CustomTabPanel from "../../../../../common/CustomTabPanel.js";
import ThresholdApprovalTabs from "./ThresholdApprovalTabs.js";
import apiRequest from "../../../../../../utils/api.js";
import { useSelector } from "react-redux";
import "../../../../css/tabs.css"

const ThresholdApprovalTable = () => {
  const [tabValue, setTabValue] = useState(0);
  const [transactionType, setTransactionType] = useState("Buy");
  const [data, setData] = useState({});

  const application = useSelector((state) => state.globalSettings.application?.data);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTransactionType(tabs[newValue]);
  };
  const tabs = ["Buy", "Sell", "Receive", "Send", "Exchange"];

  const getThresholdSettings = useCallback(async () => {
    if (application?._id) {
      const res = await apiRequest("/crypto-threshold-setting", "POST", {
        applicationId: application?._id,
      });
      if (res?.success) {
        setData(res.data);
      }
    }
  }, [application]);

  useEffect(() => {
    getThresholdSettings();
  }, [application, getThresholdSettings]);

  return (
    <Box className="mt-8">
      <p className="font-semibold text-xl px-6">Threshold Approval</p>
      <Tabs
        className="border-b-[2px] mt-4 pl-6 customTab"
        id="tabs"
        value={tabValue}
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "black" } }}
      >
        {tabs.map((item, index) => {
          return (
            <Tab
              label={item}
              key={index}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      {tabs.map((item, index) => {
        return (
          <CustomTabPanel key={index} value={tabValue} index={index}>
            <ThresholdApprovalTabs
              transactionType={transactionType}
              data={data}
              getThresholdSettings={getThresholdSettings}
            />
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};

export default ThresholdApprovalTable;
