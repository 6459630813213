import { Box, TextField, Divider } from "@mui/material";
import CommonTable from "../common/CommonTable.js";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { useSelector } from "react-redux";
import TransactionDetails from "./TranctionDetails.js";
import "./Notifications.css"
import { memo, useCallback, useEffect, useState } from "react";
import CommonDropDown from "../common/CommonDropDown.js";
import CommonPagination from "../common/CommonPagination.js";
import { Close } from "@mui/icons-material";
import apiRequest from "../../utils/api.js";
import moment from "moment";
import CommonDatePicker from "../common/CommonDatePicker.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const Notifications = () => {
    const [zoomIn, setZoomIn] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [date, setDate] = useState({ fromDate: null, toDate: null });
    const [sortConfig, setSortConfig] = useState({});
    const [searchText, setSearchText] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [notificationData, setNotificationData] = useState([])
    const [inputFields, setInputFields] = useState({ notificationType: '', notificationStatus: '' });
    const [filterItems, setFilterItems] = useState([]);
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

    useEffect(() => {
        if (searchText.length >= 3 || searchText === "") {
            setPage(1);
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [searchText]);

    const fetchNotificationData = useCallback(async () => {
        try {
            const res = await apiRequest("/get-notifications", "POST", {
                page,
                searchText: debouncedSearch,
                notificationType: inputFields.notificationType,
                notificationStatus: inputFields.notificationStatus,
                orderBy: sortConfig?.orderBy,
                sortBy: sortConfig?.sortBy,
                date
            });
            if (res.success) {
                setNotificationData(res?.data?.notifications)
                setTotalPages(res?.data?.totalCount);
            }
        } catch (err) {
            console.error(`Error in fetchTransactionList: ${err.message}`);
        }
    }, [page, debouncedSearch, sortConfig, inputFields, date]);

    useEffect(() => {
        fetchNotificationData();
    }, [fetchNotificationData])

    const handleSorting = (orderBy, sortBy) => {
        setSortConfig({ orderBy, sortBy });
    };

    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const tableHeaders = [
        { headerItem: "Notification Id" },
        { headerItem: "Type" },
        { headerItem: "Status" },
        { headerItem: "Date", sortValue: "Date" },
        { headerItem: "Priority" },
        { headerItem: "Description" },
    ];

    const renderRow = (row) => {
        return (
            <>
                <StyledTableCell>
                    {row?._id ? row?._id : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.type ? row?.type : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.status ? row?.status : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {moment(row.timestamp).format("DD/MM/YYYY HH:mm") || "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.priority ? row?.priority : "NA"}
                </StyledTableCell>
                <StyledTableCell className="w-[440px] max-w-[440px]" align="left">
                    {row?.message ? row?.message : "NA"}
                </StyledTableCell>
            </>
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputFields((prevState) => {
            const newState = {
                ...prevState,
                [name]: value,
            };
            updateFilter(name, value);
            return newState;
        });
    };

    const statusOptions = [
        { value: 'unread', label: 'unread' },
        { value: 'read', label: 'read' },
    ]

    const typeOptions = [
        { value: 'transaction-failure', label: 'transaction-failure' },
        { value: 'housekeeper-alert', label: 'housekeeper-alert' },
    ]

    const updateFilter = (label, value) => {
        setFilterItems((prevItems) => {
            const updatedItems = prevItems.map((item) =>
                item.label === label ? { ...item, value } : item
            );
            if (!updatedItems.find((item) => item.label === label)) {
                updatedItems.push({ label, value });
            }
            return updatedItems;
        });
    };

    const handleClose = (index) => {
        const filterItem = filterItems[index];
        setInputFields((prevState) => ({
            ...prevState,
            [filterItem.label]: "",
        }));
        if (filterItem.label === "Date") {
            setDate({ fromDate: null, toDate: null });
        }
        const updatedFilterItems = filterItems.filter((_, i) => i !== index);
        setFilterItems(updatedFilterItems);
    };

    const handleZoomClick = () => {
        setZoomIn(!zoomIn);
    };

    const resetFilters = () => {
        setInputFields({
            notificationType: '',
            notificationStatus: ''
        });
        setDate({});
        setFilterItems([]);
    };

    const setDateRange = (startDate, endDate) => {
        setDate({
            fromDate: startDate,
            toDate: endDate,
        });
        const dateRange = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
        updateFilter("Date", dateRange);
    };

    const displayLabel = {
        notificationType: "Type",
        notificationStatus: "Status"
    };

    return (
        <Box className="flex w-full border-[1px] rounded-lg	h-full">
            {!zoomIn ? <Box className="rounded-lg pb-4 h-full w-[70%]">
                <Box className="m-6 flex flex-col gap-6">
                    <Box className="flex gap-3 items-center">
                        <p className="font-semibold text-xl">Notifications</p>
                        <p className="bg-red-500 text-white text-xs rounded-full w-6 flex items-center justify-center">6+</p>
                    </Box>
                    <Divider className="mx-6 pt-2" />
                    <div className="flex gap-4 mr-4 items-center">
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Notifications</p>
                            <TextField
                                className="w-64 rounded-lg h-10"
                                placeholder="Search notifications"
                                type="search"
                                size="small"
                                onChange={handleChangeSearchText}
                                value={searchText}
                                InputProps={{
                                    endAdornment: (
                                        <img
                                            src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                            alt="search"
                                            className="w-6 h-6"
                                        />
                                    ),
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Type</p>
                            <CommonDropDown
                                className="bg-white w-48 h-12"
                                placeHolder="All"
                                options={typeOptions}
                                value={inputFields?.notificationType}
                                onChange={handleInputChange}
                                name="notificationType" /></div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Status</p>
                            <CommonDropDown
                                className="bg-white w-48 h-12"
                                placeHolder="All"
                                options={statusOptions}
                                value={inputFields?.notificationStatus}
                                onChange={handleInputChange}
                                name="notificationStatus" /></div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Date</p>
                            <div>
                                <CommonDatePicker setDateRange={setDateRange} startDate={date.fromDate} endDate={date.toDate} />
                            </div>
                        </div>
                    </div>
                    <div className="filter-container flex flex-wrap gap-4">
                        {filterItems?.map((filterItem, index) => (
                            <div key={index} className="filter-item flex items-center gap-2 p-1 rounded-md bg-[#F4F4F5]">
                                <p className="filter-label font-medium leading-4 text-sm text-gray-600">
                                    {displayLabel[filterItem?.label]
                                        ? `${displayLabel[filterItem?.label]} : ${filterItem?.value}`
                                        : `${filterItem?.label}: ${filterItem?.value}`}
                                </p>
                                <Close
                                    className="cursor-pointer w-4  h-4"
                                    fontSize="16px"
                                    onClick={() => handleClose(index)}
                                />
                            </div>
                        ))}
                        <button className="text-blue-500" onClick={resetFilters}>Reset all({filterItems?.length})</button>
                    </div>
                </Box>
                <div className="flex flex-col h-[70vh]">
                    <div className="h-5.5/6 overflow-y-auto">
                        <CommonTable
                            headers={tableHeaders}
                            renderRow={renderRow}
                            data={notificationData}
                            sorting={handleSorting}
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                        />
                    </div>
                    <CommonPagination totalPages={totalPages} setPage={setPage} page={page} />
                </div>
            </Box> : null}
            <Box className="flex-1">
                <TransactionDetails handleZoomClick={handleZoomClick} zoomIn={zoomIn} />
            </Box>
        </Box>
    );
};

export default memo(Notifications);
