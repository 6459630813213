import React, { useState, useEffect } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../CommonTable.js";
import { useSelector, useDispatch } from "react-redux";
import CopyButton from "../../../../common/CopyButton.js";
import useDebounce from "../../../../../customHook/useDebounce.js";
import apiRequest from "../../../../../utils/api.js";
import { setSingleApplication } from "../../../../../redux/actions/globalSettingsAction.js";

const IpWhiteListing = () => {
  const [tableData, setTableData] = useState([{ ipAddress: "" }]);
  const [openToast, setOpenToast] = useState("");

  const dispatch = useDispatch();
  const application = useSelector(
    (state) => state.globalSettings.application?.data
  );
  const headers = [{ label: "Server Name" }, { label: "IP Address" }];

  useEffect(() => {
    if (application?.whiteListIpAddress) {
      setTableData([{ ipAddress: application?.whiteListIpAddress }]);
    } else {
      setTableData([{ ipAddress: "" }]);
    }
  }, [application]);

  const updateIp = async (newIp) => {
    try {
      const res = await apiRequest("/update-application-data", "POST", {
        applicationId: application?._id,
        whiteListIpAddress: newIp,
      });

      if (res?.success) {
        dispatch(setSingleApplication(res?.data));
        setOpenToast("IP Address updated sucessfully");
      } else {
        setOpenToast(res?.error);
      }
    } catch (err) {
      console.error(err);
      setOpenToast(err);
    }
  };

  const debouncedUpdateIp = useDebounce((ip) => {
    updateIp(ip);
  }, 500);

  const handleIpChange = (e) => {
    setTableData([{ ipAddress: e.target.value }]);
    debouncedUpdateIp(e.target.value);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const renderRow = (row, index) => {
    return (
      <>
        <TableCell component="th" scope="row" className="w-60">
          <Box className="flex items-center gap-4">
            <p className="text-gray-900 w-80">Dev: </p>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="flex gap-2">
            <input
              className="w-2/5 border-[1px] p-[10px] pl-[14px] rounded-lg"
              value={row?.ipAddress || ""}
              onChange={(e) => handleIpChange(e)}
              placeholder="Enter IP Address"
            />
            <Box className="border-[1px] rounded-lg">
              <CopyButton textToCopy={row?.ipAddress} />
            </Box>
          </Box>
        </TableCell>
      </>
    );
  };

  return (
    <Box className="">
      <p className="font-semibold text-lg px-6 mb-6 mt-8">IP Whitelisting</p>
      <CommonTable headers={headers} renderRow={renderRow} data={tableData} />
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default IpWhiteListing;
