import { FormControl, MenuItem, Modal, Select } from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../common/CommonButton.js";
import { useSelector } from "react-redux";

const Transfer = (props) => {
  const [value, setValue] = useState({
    select: "",
    walletType: "",
    TransferAmount: "",
  });
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      className="flex justify-center items-center"
    >
      <div className="flex bg-white  p-4 px-8 h-max rounded-xl transfer-balance">
        <form className="flex flex-col gap-8 ">
          <div className="flex justify-between">
            <p className="text-2xl text-zinc-900 font-semibold">Transfer</p>
            <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                alt="Close"
                onClick={props.close}
                className="w-6 h-6 cursor-pointer hover:bg-gray-200"
              />
          </div>
          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800 flex items-center">
              Wallet type:
            </p>
            <FormControl>
              <Select
                name="select"
                value={value?.select}
                onChange={(e) => handleChange(e)}
                className="w-80 bg-zinc-100 border-zinc-200 select-classes"
                size="small"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em className="text-zinc-600">Select type</em>
                </MenuItem>
                <MenuItem value="Buy">Buy</MenuItem>
                <MenuItem value="Sell">Sell</MenuItem>
                <MenuItem value="Send">Send</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800 flex items-center">
              Select wallet:
            </p>
            <FormControl>
              <Select
                name="walletType"
                value={value?.walletType}
                onChange={(e) => handleChange(e)}
                className="w-80 bg-zinc-100 border-zinc-200 select-classes"
                size="small"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em className="text-zinc-600">Select wallet</em>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex gap-8">
            <p className="font-normal text-base text-zinc-800 flex items-center">
              Transfer amount:
            </p>
            <FormControl>
              <Select
                name="TransferAmount"
                value={value?.TransferAmount}
                onChange={(e) => handleChange(e)}
                className="w-80 bg-zinc-100 border-zinc-200 select-classes"
                size="small"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em className="text-zinc-600">300</em>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex justify-between gap-8">
            <p className="font-normal text-base text-zinc-800 flex items-center">
              Estimated gas fee:
            </p>
            <span className="flex justify-end"> 1 USDT = 1 USD</span>
          </div>
          <div className="flex justify-end ">
            <CommonButton
              text="Transfer funds"
              bgColor="black"
              borderRadius="rounded-3xl"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default Transfer;
