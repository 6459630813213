const getKey = async (key) => {
  const enc = new TextEncoder();
  return crypto.subtle.importKey(
    "raw",
    enc.encode(key),
    { name: "AES-GCM" },
    false,
    ["encrypt", "decrypt"]
  );
};

const getIv = () => {
  return crypto.getRandomValues(new Uint8Array(12));
};

export const encryptData = async (data, key) => {
  const encodedData = new TextEncoder().encode(JSON.stringify(data));
  const cryptoKey = await getKey(key);
  const iv = getIv();
  const encryptedData = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    cryptoKey,
    encodedData
  );

  return {
    iv: Array.from(iv),
    data: Array.from(new Uint8Array(encryptedData)),
  };
};

export const decryptData = async (encryptedData, key) => {
  const { iv, data } = encryptedData;
  const cryptoKey = await getKey(key);
  const decryptedData = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv: new Uint8Array(iv) },
    cryptoKey,
    new Uint8Array(data)
  );

  return JSON.parse(new TextDecoder().decode(decryptedData));
};
