import React from "react";
import { TextField, Alert } from "@mui/material";
import PasswordField from "./PasswordField.js";
import RememberMe from "./RememberMe.js";
import CommonButton from "../common/CommonButton.js";

const LoginForm = ({
  textInput,
  handleChange,
  handleFormSubmit,
  isPasswordVisible,
  setIsPasswordVissible,
  error,
}) => {
  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex flex-col gap-6 items-center justify-center">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            {error && (
              <Alert severity="error" className="mb-4">
                {error}
              </Alert>
            )}
            <div className="font-medium text-sm text-slate-700 mb-1">Email</div>
            <TextField
              className="custom-password-field"
              fullWidth
              placeholder="Enter your email"
              type="email"
              variant="outlined"
              name="email"
              value={textInput?.email}
              onChange={handleChange}
              required
            />
          </div>
          <PasswordField
            textInput={textInput}
            handleChange={handleChange}
            isPasswordVisible={isPasswordVisible}
            setIsPasswordVissible={setIsPasswordVissible}
          />
        </div>
        <RememberMe textInput={textInput} handleChange={handleChange} />
        <div className="flex flex-col items-center w-full mb-2 gap-4">
          <CommonButton text="Log in" bgColor="black" width="w-full" />
        </div>
      </div>
    </form>
  );
};
export default LoginForm;
