import { Box, Divider } from "@mui/material";
import SweepsTabs from "./SweepsTabs.js";

const Sweeps = () => {
    return (
        <Box className="border-[1px] rounded-lg pb-4 h-full">
            <Box className="flex gap-6 p-6 items-center h-[5vh]">
                <p className="font-semibold text-xl">Sweeps</p>
                <Divider orientation="vertical" variant="middle" flexItem />
            </Box>
            <SweepsTabs />
        </Box>
    );
};

export default Sweeps;