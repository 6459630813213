import React, { memo } from "react";
import { Divider, Modal, TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material/index.js";
import CommonButton from "../common/CommonButton.js";
import Checkbox from "../common/CheckBox.js";
import { useSelector } from "react-redux";
import CommonDropDown from "../common/CommonDropDown.js";
import apiRequest from "../../utils/api.js";
import * as Yup from "yup";
import UpdateSweepConfig from "../../formSchema/UpdateSweepConfig.js";
import { Form, Field } from "react-final-form";

const validateForm = (values) => {
  try {
    const schema = Yup.object().shape(
      UpdateSweepConfig.reduce(
        (acc, curr) => ({ ...acc, [curr.name]: curr.validation }),
        {}
      )
    );
    schema.validateSync(values, { abortEarly: false });
    return {};
  } catch (err) {
    return err.inner.reduce(
      (acc, curr) => ({ ...acc, [curr.path]: curr.message }),
      {}
    );
  }
};

const UpdateConfiguration = (props) => {
  const {
    open,
    close,
    cryptoCurrencyList,
    setReload,
    paymentProviderUpdateItem,
  } = props;
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const onSubmit = async (values, { reset }) => {
    try {
      const body = {
        id: paymentProviderUpdateItem?.id,
        crypto: values?.crypto,
        minFullWallets: +values?.remainingFullWalletsAfterSweep,
        minAmountUsd: +values?.minAmountInWalletToBeSwept,
        archive: Boolean(values?.archive),
        takeAllFunds: Boolean(values?.takeAllFunds),
        maxAmountUsd: +values?.maxAmountInWalletToBeSwept,
        maxAmount: +values?.maxAcceptableGasFeeAmount,
        maxPercent: +values?.maxAcceptableGasFeeAsPercentSweepAmount,
        sweepFlag: Boolean(values?.sweepFlag),
        configFlag: Boolean(values?.configFlag),
      };
      await apiRequest("/update-sweep-config", "POST", {
        ...body,
      });
    } catch (error) {
      console.error("Error in sweepConfig update", error);
    } finally {
      reset();
      setReload((prev) => !prev);
      close();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => close()}
      className="flex justify-center items-center"
    >
      <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          crypto:
            cryptoCurrencyList?.length && paymentProviderUpdateItem?.cryptoId
              ? paymentProviderUpdateItem.cryptoId
              : "",
          remainingFullWalletsAfterSweep:
            paymentProviderUpdateItem?.minFullWalletThresholds,
          minAmountInWalletToBeSwept:
            paymentProviderUpdateItem?.minUsdThresholds,
          maxAmountInWalletToBeSwept:
            paymentProviderUpdateItem?.maxUsdThresholds,
          maxAcceptableGasFeeAmount:
            paymentProviderUpdateItem?.maxFeeThresholds,
          maxAcceptableGasFeeAsPercentSweepAmount: parseInt(
            paymentProviderUpdateItem?.maxPercentageThresholds
          ),
          takeAllFunds: paymentProviderUpdateItem?.takeAllFunds,
          archive: paymentProviderUpdateItem?.archive,
          sweepFlag: paymentProviderUpdateItem?.sweepFlag,
          configFlag: paymentProviderUpdateItem?.configFlag,
        }}
        validate={validateForm}
        render={({
          handleSubmit,
          form,
          submitting,
          invalid,
          pristine,
          values,
          errors,
          touched,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col bg-white h-max rounded-xl add-configuration"
          >
            <div className="p-4 px-8">
              <div className="flex flex-col gap-10">
                <div className="flex justify-between">
                  <p className="text-2xl text-zinc-900 font-semibold">
                    Update Configuration
                  </p>
                  <CloseOutlined onClick={close} className="cursor-pointer" />
                </div>
                <div className="flex flex-col gap-8 overflow-y-auto max-h-96 scroll-hidden">
                  <Field name="crypto">
                    {({ input, meta }) => {
                      return (
                        <div className="flex justify-between items-center">
                          <p className="font-normal text-base text-zinc-800 flex items-center">
                            Crypto :
                          </p>
                          <CommonDropDown
                            required={true}
                            disabled={true}
                            options={cryptoCurrencyList?.map((item) => ({
                              value: item?._id,
                              label: item?.symbol,
                            }))}
                            name="crypto"
                            placeHolder="Tether(USDT)"
                            value={input?.value}
                            onChange={input?.onChange}
                            onBlur={input?.onBlur}
                            touched={meta?.touched}
                            error={meta?.error}
                            image={
                              <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/usdt.png`}
                                alt="add-icon"
                                className="w-5 h-5"
                              />
                            }
                          />
                        </div>
                      );
                    }}
                  </Field>

                  <Field name="remainingFullWalletsAfterSweep">
                    {({ input, meta }) => {
                      return (
                        <div className="flex justify-between items-center">
                          <p className="font-normal text-base text-zinc-800 flex items-center">
                            Remaining full
                            <br /> wallets after <br />
                            sweep :
                          </p>
                          <TextField
                            className="text-field"
                            type="number"
                            name="remainingFullWalletsAfterSweep"
                            variant="outlined"
                            size="small"
                            value={input?.value}
                            onChange={input?.onChange}
                            onBlur={input?.onBlur}
                            touched={meta?.touched?'1': undefined}
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </div>
                      );
                    }}
                  </Field>

                  <Field name="minAmountInWalletToBeSwept">
                    {({ input, meta }) => {
                      return (
                        <div className="flex justify-between items-center">
                          <p className="font-normal text-base text-zinc-800 flex items-center">
                            Min. amount in
                            <br /> wallet to be <br />
                            swept :
                          </p>
                          <TextField
                            className="text-field"
                            type="number"
                            name="minAmountInWalletToBeSwept"
                            variant="outlined"
                            size="small"
                            value={input?.value}
                            onChange={input?.onChange}
                            onBlur={input?.onBlur}
                            touched={meta?.touched?'1': undefined}
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="maxAmountInWalletToBeSwept">
                    {({ input, meta }) => {
                      return (
                        <div className="flex justify-between items-center">
                          <p className="font-normal text-base text-zinc-800 flex items-center">
                            Max. amount in
                            <br /> wallet to be
                            <br /> swept :
                          </p>
                          <TextField
                            className="text-field"
                            type="number"
                            name="maxAmountInWalletToBeSwept"
                            variant="outlined"
                            size="small"
                            value={input?.value}
                            onChange={input?.onChange}
                            onBlur={input?.onBlur}
                            touched={meta?.touched?'1': undefined}
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <div>
                    <Field name="takeAllFunds">
                      {({ input, meta }) => (
                        <div className="flex gap-4 items-center">
                          <Checkbox
                            name="takeAllFunds"
                            checked={input?.value}
                            handleChange={(e) => {
                              form.change(e?.target?.name, e?.target?.checked);
                            }}
                          />
                          <p>Take All Funds</p>
                        </div>
                      )}
                    </Field>

                    <Field name="archive">
                      {({ input, meta }) => (
                        <div className="flex gap-4 items-center">
                          <Checkbox
                            name="archive"
                            checked={input?.value}
                            handleChange={(e) => {
                              form.change(e?.target?.name, e?.target?.checked);
                            }}
                          />
                          <p>Set Wallet Status To Archived After Sweep </p>
                        </div>
                      )}
                    </Field>
                  </div>
                  <Divider />
                  <div>
                    <p className="text-xl font-medium">Gas Fees</p>
                  </div>

                  <Field name="maxAcceptableGasFeeAmount">
                    {({ input, meta }) => (
                      <div className="flex justify-between items-center">
                        <p className="font-normal text-base text-zinc-800 flex items-center">
                          Max. acceptable
                          <br /> gas fee amount :
                        </p>
                        <TextField
                          className="text-field"
                          type="number"
                          name="maxAcceptableGasFeeAmount"
                          variant="outlined"
                          size="small"
                          value={input?.value}
                          onChange={input?.onChange}
                          onBlur={input?.onBlur}
                          touched={meta?.touched?'1': undefined}
                          error={meta.touched && !!meta.error}
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="maxAcceptableGasFeeAsPercentSweepAmount">
                    {({ input, meta }) => {
                      return (
                        <div className="flex gap-8 items-center">
                          <p className="font-normal text-base text-zinc-800 flex items-center">
                            Max. acceptable
                            <br /> gas fee as a % of <br />
                            sweep amount :
                          </p>
                          <TextField
                            className="text-field"
                            type="number"
                            name="maxAcceptableGasFeeAsPercentSweepAmount"
                            variant="outlined"
                            size="small"
                            value={input?.value}
                            onChange={input?.onChange}
                            onBlur={input?.onBlur}
                            touched={meta?.touched?'1': undefined}
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <div>
                    <Field name="sweepFlag">
                      {({ input, meta }) => {
                        return (
                          <div className="flex gap-4 items-center">
                            <Checkbox
                              name="sweepFlag"
                              checked={input?.value}
                              handleChange={(e) => {
                                form.change(
                                  e?.target?.name,
                                  e?.target?.checked
                                );
                              }}
                            />
                            <p>Perform Actual Sweep Transaction</p>
                          </div>
                        );
                      }}
                    </Field>

                    <Field name="configFlag">
                      {({ input, meta }) => {
                        return (
                          <div className="flex gap-4 items-center">
                            <Checkbox
                              name="configFlag"
                              checked={input?.value}
                              handleChange={(e) => {
                                form.change(
                                  e?.target?.name,
                                  e?.target?.checked
                                );
                              }}
                            />
                            <p>Config Enabled/Disable</p>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <Divider />
              <div className="flex justify-end p-4 px-8">
                <CommonButton
                  disabled={submitting || invalid}
                  text="Update Config"
                  bgColor="black"
                  width="w-36"
                  borderRadius="rounded-3xl"
                  type="submit"
                />
              </div>
            </div>
          </form>
        )}
      />
      </>
    </Modal>
  );
};

export default memo(UpdateConfiguration);
