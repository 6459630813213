import { combineReducers } from "redux";
import globalSettingsReducer from "./globalSettingsReducer.js";
import commonReducer from "./commonReducer.js";
import configReducer from "./configReducer.js";

const rootReducer = combineReducers({
  config: configReducer,
  globalSettings: globalSettingsReducer,
  common: commonReducer,
});

export default rootReducer;
