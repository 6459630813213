import React from "react";

const RememberMe = ({ textInput, handleChange }) => {
  return (
    <div className="flex gap-5 justify-center items-center">
      <div className="flex gap-x-1">
        <input
          className="transform scale-110"
          type="checkbox"
          name="isChecked"
          checked={textInput?.isChecked}
          onChange={handleChange}
        />
        <label className="text-sm font-medium py-2 text-[#344054]">
          Remember for 30 days
        </label>
      </div>
      <div>
        <p className="text-sm font-medium cursor-pointer text-blue-600">
          Forgot Password
        </p>
      </div>
    </div>
  );
};
export default RememberMe;
